<template>
  <section class="lang_ko main" style="zoom: 1;" v-if="room && room.data">
    <div class="container_game" :class="{'vod_88casino': companyKey === '88casino'}" id="container_game">

      <div class="mask"></div>
      <div class="bet_history" id="bet_history" style="display: none;"></div>
      <div class="change_table_pop" id="change_table_pop"></div>

      <div class="notice" v-if="!$API.util.isMobile()">
        <div class="content">
          <p id="liveNotice">
            <vue3-marquee>Have a nice day~!!</vue3-marquee>
          </p>
        </div>
      </div>

      <!-- 왼쪽 영상 프레임 //-->
      <div class="" :class="{'vod_88casino': companyKey === '88casino'}">
        <div class="video_wrap">

          <radial-progress-bar-component style="z-index: 99995;" ref="radial-progress" @tryBet="tryBet"/>

          <div class="menu">
            <a class="zoomin" @click="zoomInVideo();"><p>영상확대</p></a>
            <a class="zoomout" @click="zoomOutVideo();"><p>영상축소</p></a>
          </div>

          <iframe id="video" ref="video-iframe" :src="mediaUrl" scrolling="no" border="no" maginwidth="0"
                  marginheight="0"
                  frameborder="0"></iframe>
        </div>
      </div>

      <!-- 영상 컨트롤 프레임 //-->
      <div class="video_info">
        <ul class="btn">
          <li class="refresh" @click="restartVideo"><p>영상새로고침</p></li>

          <li :class="{'mute': enableSound === true, 'sound': enableSound === false}" @click="soundOnOff"><p>음소거</p>
          </li>


        </ul>
        <ul class="menu">
          <li class="main_video" @click="restartVideo"><p>메인영상</p></li>
          <li class="sub_video"><p>서브영상</p></li>
        </ul>
      </div>

      <!-- 우측 배팅판 //-->
      <div class="game_info info"><!-- 게임정보 -->
        <div>
          <img :src="`/imgs/company/${company.key}.png`" class="game_box_img">

          <div class="user">ID : <span v-if="user">{{ user.nickname }}</span></div>

          <div class="table_no">방번호 : {{ room == null ? '' : room.name }} 바카라</div>
          <div class="table_no header_game">
            <div class="game_round">
              <dl>
                <dt>차수</dt>
                <dd id="gameNum">{{ currentRoom === null ? 0 : (count.p + count.b + count.t + 1) }}</dd>
              </dl>
            </div>
            <ul class="info">
              <dt>게임 No.</dt>
              <li>
                <span v-if="room && room.game.state !== 'shoechange' && room.game.state !== 'maintenance'">{{
                    room == null ? 0 : room.game.roundNum.toThousands()
                  }}</span>
                <span
                    v-if="room && room.game.state === 'shoechange' || room.game.state === 'maintenance'">게임 대기중입니다.</span>
              </li>
            </ul>
          </div>
          <!-- div class="time">(UTC + 8) <span id="time"></span></div -->
        </div>

        <div class="game_stats">
          <div class="user_money">잔액 : <strong id="userMoney" v-if="user">{{
              $API.util.getFormattedMoney(user.cash)
            }}</strong></div>
          <ul class="menu">
            <li class="history showMask" @click="openBets"><p>베팅이력</p></li>
            <li class="home" @click="goLobby"><p>로비<img src="/ps/images/ic_exit.png"></p></li>
          </ul>
        </div>

        <div class="game_stats">
          <div class="bet_money">총 베팅금액 : <strong
              id="totalBetMoney">{{ (currentBetAmount + currentTotalBetAmount).toThousands() }}</strong></div>
          <ul class="menu">
            <li class="open_table" @click="openRoomMove">방이동</li>
          </ul>
        </div>

        <div class="grade">


          <ul>
            <li>최소한도 <strong
                id="totalBetMoney">{{
                currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pb[0])
              }}</strong>
            </li>
            <li>최대한도 <strong
                id="totalBetMoney">{{
                currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pb[1])
              }}</strong>
            </li>
          </ul>
          <ul>
            <li>타이 최소한도 <strong
                id="totalBetMoney">{{
                currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].t[0])
              }}</strong>
            </li>
            <li>타이 최대한도 <strong
                id="totalBetMoney">{{
                currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].t[1])
              }}</strong>
            </li>
          </ul>
          <ul>
            <li>페어 최소한도 <strong
                id="totalBetMoney">{{
                currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pair[0])
              }}</strong>
            </li>
            <li>페어 최대한도 <strong
                id="totalBetMoney">{{
                currentRoom == null ? 0 : $API.util.getFormattedMoney(currentRoom.minmax[minmaxIndex].pair[1])
              }}</strong>
            </li>
          </ul>


        </div>
      </div><!-- 게임정보 -->

      <div class="slc_room otherRoom" id="move-room-wrapper" style="height: 560px; overflow-y: auto; z-index: 9999999;">
        <div class="slc_room_title" style="width: 100%;">
          이동하실 테이블을 선택하세요.
          <button type="button" class="btn_image" @click="closeRoomMove"><img src="/resources/img/close-btn1.png">
          </button>
        </div>

        <div :class="{game_list: true, nomulti: true}">
          <div class="blocks grid-1"
               :class="{'mobile-layout': $API.util.isMobile() === true, 'desktop-layout': $API.util.isMobile() === false}"
               style="text-align: center;">
            <lobby-component ref="move-room-wrapper" :companyKey="companyKey" :lobbyColType="0" :lobbyHistoryType="2"/>
          </div>
        </div>
      </div>

      <room-card-panel-component ref="room-card-panel-mobile" :room="room"
                                 v-show="showCardResult && $API.util.isMobile()"></room-card-panel-component>

      <div class="game_wrap" id="game_wrap"><!-- 게임판 -->
        <div class="game_panel"><!--배팅판-->

          <div class="notice" :class="{'hide': room.game.state === 'betting' || room.game.state === 'nomorebet'}">
            <span v-if="showBetEndingText">베팅 종료</span>
            <span v-else-if="room.game.state === 'shoechange'">셔플중 입니다.</span>
            <span v-else-if="room.game.state !== 'betting' && showBetFinishText">베팅이 마감되었습니다.</span>
            <span v-else-if="isCancelRound">베팅 취소인 게임입니다.</span>
          </div>
          <div class="game_item_wrap" id="game_item_wrap">


            <div class="game_item item_1 playerPair" :class="{'win': roundResult.pp === true}" title="바카라 플레이어 페어"
                 @click="addBet('pp')"><!-- 승리시 game_item에 win 클래스 추가하면 어둡게 변함 -->
              <dl>
                <dt>P.P</dt>
                <p>플레이어 페어</p>
                <p>1 : 11</p>
                <dd id="gameItemPlayerPair" :class="{
                  'c1000': (bet.pp + totalBet.pp) > 0 && (bet.pp + totalBet.pp) < minmax.chips[1],
                  'c10000': (bet.pp + totalBet.pp) >= minmax.chips[1] && (bet.pp + totalBet.pp) < minmax.chips[2],
                  'c50000': (bet.pp + totalBet.pp) >= minmax.chips[2] && (bet.pp + totalBet.pp) < minmax.chips[3],
                  'c100000': (bet.pp + totalBet.pp) >= minmax.chips[3] && (bet.pp + totalBet.pp) < minmax.chips[4],
                  'c500000': (bet.pp + totalBet.pp) >= minmax.chips[4] && (bet.pp + totalBet.pp) < minmax.chips[5],
                  'c1000000': (bet.pp + totalBet.pp) >= minmax.chips[5]
                }">{{ $API.util.getFormattedMoney(bet.pp + totalBet.pp) }}
                </dd><!-- class="c1000 ~ c1000000" -->
              </dl>
            </div>
            <div class="game_item item_2 tie" :class="{'win': roundResult.t === true}" title="바카라 타이"
                 @click="addBet('t')">
              <dl>
                <dt>Tie</dt>
                <p>타이</p>
                <p>1 : 8</p>
                <dd id="gameItemTie" :class="{
                  'c1000': (bet.t + totalBet.t) > 0 && (bet.t + totalBet.t) <= minmax.chips[1],
                  'c10000': (bet.t + totalBet.t) >= minmax.chips[1] && (bet.t + totalBet.t) < minmax.chips[2],
                  'c50000': (bet.t + totalBet.t) >= minmax.chips[2] && (bet.t + totalBet.t) < minmax.chips[3],
                  'c100000': (bet.t + totalBet.t) >= minmax.chips[3] && (bet.t + totalBet.t) < minmax.chips[4],
                  'c500000': (bet.t + totalBet.t) >= minmax.chips[4] && (bet.t + totalBet.t) < minmax.chips[5],
                  'c1000000': (bet.t + totalBet.t) >= minmax.chips[5]
                }">{{ $API.util.getFormattedMoney(bet.t + totalBet.t) }}
                </dd>
              </dl>
            </div>
            <div class="game_item item_3 bankerPair" :class="{'win': roundResult.bp === true}" title="바카라 뱅커 페어"
                 @click="addBet('bp')">
              <dl>
                <dt>B.P</dt>
                <p>뱅커 페어</p>
                <p>1 : 11</p>
                <dd id="gameItemBankerPair" :class="{
                  'c1000': (bet.bp + totalBet.bp) > 0 && (bet.bp + totalBet.bp) <= minmax.chips[1],
                  'c10000': (bet.bp + totalBet.bp) >= minmax.chips[1] && (bet.bp + totalBet.bp) < minmax.chips[2],
                  'c50000': (bet.bp + totalBet.bp) >= minmax.chips[2] && (bet.bp + totalBet.bp) < minmax.chips[3],
                  'c100000': (bet.bp + totalBet.bp) >= minmax.chips[3] && (bet.bp + totalBet.bp) < minmax.chips[4],
                  'c500000': (bet.bp + totalBet.bp) >= minmax.chips[4] && (bet.bp + totalBet.bp) < minmax.chips[5],
                  'c1000000': (bet.bp + totalBet.bp) >= minmax.chips[5]
                }">{{ $API.util.getFormattedMoney(bet.bp + totalBet.bp) }}
                </dd>
              </dl>
            </div>
            <div class="game_item item_4 player" :class="{'win': roundResult.p === true}" title="플레이어"
                 @click="addBet('p')">
              <dl>
                <dt>Player</dt>
                <p>플레이어</p>
                <p>1:1</p>
                <dd id="gameItemPlayer" :class="{
                  'c1000': (bet.p + totalBet.p) > 0 && (bet.p + totalBet.p) <= minmax.chips[1],
                  'c10000': (bet.p + totalBet.p) >= minmax.chips[1] && (bet.p + totalBet.p) < minmax.chips[2],
                  'c50000': (bet.p + totalBet.p) >= minmax.chips[2] && (bet.p + totalBet.p) < minmax.chips[3],
                  'c100000': (bet.p + totalBet.p) >= minmax.chips[3] && (bet.p + totalBet.p) < minmax.chips[4],
                  'c500000': (bet.p + totalBet.p) >= minmax.chips[4] && (bet.p + totalBet.p) < minmax.chips[5],
                  'c1000000': (bet.p + totalBet.p) >= minmax.chips[5]
                }">{{ $API.util.getFormattedMoney(bet.p + totalBet.p) }}
                </dd>
              </dl>
            </div>
            <div class="game_item item_5 banker" :class="{'win': roundResult.b === true}" title="뱅커"
                 @click="addBet('b')">
              <dl>
                <dt>Banker</dt>
                <p>뱅커</p>
                <p>1 : 0.95</p>
                <dd id="gameItemBanker" :class="{
                  'c1000': (bet.b + totalBet.b) > 0 && (bet.b + totalBet.b) < minmax.chips[1],
                  'c10000': (bet.b + totalBet.b) >= minmax.chips[1] && (bet.b + totalBet.b) < minmax.chips[2],
                  'c50000': (bet.b + totalBet.b) >= minmax.chips[2] && (bet.b + totalBet.b) < minmax.chips[3],
                  'c100000': (bet.b + totalBet.b) >= minmax.chips[3] && (bet.b + totalBet.b) < minmax.chips[4],
                  'c500000': (bet.b + totalBet.b) >= minmax.chips[4] && (bet.b + totalBet.b) < minmax.chips[5],
                  'c1000000': (bet.b + totalBet.b) >= minmax.chips[5]
                }">{{ $API.util.getFormattedMoney(bet.b + totalBet.b) }}
                </dd>
              </dl>
            </div>
          </div>

          <room-chip-panel-component :chips="minmax.chips" :chipIndex="selectedChipIndex"
                                     @changeChipIndex="changeChipIndex"/>

          <div class="game_btns"><!--버튼-->
            <div class="betting"
                 v-if="room && room.game && (room.game.state === 'betting' || room.game.state === 'nomorebet')">
              <button class="rebet notConfirmBtn on" id="btnRebet" @click="retryAddBet">다시</button>
              <button :class="{'cancel': true, 'cancel on': currentBetAmount > 0}" id="btnCancelBetting"
                      @click="cancelBets"><span>베팅취소</span></button>
            </div>
            <!--
            <div class="apply" v-if="room && room.game && room.game.state === 'nomorebet'">
              <button class="rebet notConfirmBtn"><span>다시</span></button>
              <button class="cancel on" @click="cancelBets" id="btnCancelBetting"><span>베팅취소</span></button>
            </div>
            //-->
            <div class="waiting"
                 v-if="room && room.game && room.game.state !== 'nomorebet' && room.game.state !== 'betting'">
              <div class="waiting show">
                <button class="rebet notConfirmBtn"><span>다시</span></button>
                <button class="cancel "><span>베팅취소</span></button>
              </div>
            </div>


          </div><!--버튼-->
        </div><!--배팅판-->
      </div><!-- 게임판 끝 -->
      <div class="game_info_mid">


        <dl>
          <dt>횟수</dt>
          <dd>{{ currentRoom === null ? 0 : (count.p + count.b + count.t) }}</dd>
          <dt>B</dt>
          <dd>{{ currentRoom == null ? 0 : count.b }}</dd>
          <dt>P</dt>
          <dd>{{ currentRoom == null ? 0 : count.p }}</dd>
          <dt>T</dt>
          <dd>{{ currentRoom == null ? 0 : count.t }}</dd>

          <dt>BP</dt>
          <dd>{{ currentRoom == null ? 0 : count.bp }}</dd>
          <dt>PP</dt>
          <dd>{{ currentRoom == null ? 0 : count.pp }}</dd>
        </dl>


      </div>

      <div class="choice_bet">
        <div class="icons">
          <a id="player_future" class="future-player" href="javascript:void(0);" @click="renderPreview('30')">
            <ul>
              <li class="bluePlayerFullCircle">Player</li>
              <p v-if="!$API.util.isMobile()">NEXT 게임 플레이어</p>
              <li class="blueLineCircle">
                <button type="button" class="future-one future-blue"></button>
              </li>
              <li class="redFullCircle">
                <button type="button" class="future-two future-blue-circle"></button>
              </li>
              <li class="redDiagLine">
                <button type="button" class="future-three future-blue-slash"></button>
              </li>
            </ul>
          </a>
        </div>
        <div class="icons">
          <a id="banker_future" class="future-banker" href="javascript:void(0);" @click="renderPreview('10')">
            <ul>
              <li class="redBankerFullCircle">Banker</li>
              <p v-if="!$API.util.isMobile()">NEXT 게임 뱅커</p>
              <li class="redLineCircle">
                <button type="button" class="future-one future-red"></button>
              </li>
              <li class="blueFullCircle">
                <button type="button" class="future-two future-red-circle"></button>
              </li>
              <li class="blueDiagLine">
                <button type="button" class="future-three future-red-slash"></button>
              </li>
            </ul>
          </a>
        </div>
      </div>
      <room-card-panel-component ref="room-card-panel" :room="room"
                                 v-show="showCardResult === true && !$API.util.isMobile()"
                                 @images-loaded="showFinalResult"></room-card-panel-component>

      <div class="roadmap roadmapInMain" id="roadMap-1" style="width: 100%;" v-if="room"> <!-- 로드맵 시작 -->

        <div class="section-3">
          <div class="result_area">
            <div class="places" style="background-color: white;">
              <room-history-panel-component :currentRoom="room" ref="room-history-panel-component"
                                            v-if="$API.util.isMobile()"/>
              <room-history-panel-component2 :currentRoom="room" ref="room-history-panel-component"
                                             @show-popup="showPopup" @hide-popup="hidePopup"
                                             v-if="!$API.util.isMobile()"/>
            </div>
          </div>
        </div>
      </div>
      <!-- 로드맵 끝 -->
    </div>
  </section>

  <div id="pop_message" :class="{'popup_message': alert.showBack === true}" v-show="alert.visible">
    <div :class="{popup_black: alert.type == 1, popup_red: alert.type == 2, popup_blue: alert.type == 3}">
      <div class="popup_cont">
        <h1 v-html="alert.msg"></h1>
      </div>
      <div class="btn_area">
        <button type="button" class="btn btn_sm btn_grd2"
                style="background-color: #3C3C3C; padding: 6px; color: #ffffff" @click="hideAlert()">닫기
        </button>
      </div>
    </div>
  </div>
  <bet-history-component :company="company" :table="room" @closeBets="closeBets" id="bet-history" ref="bet-history"/>

  <div v-if="false" id="sound_config" class="popup">
    <div class="popup_box">
      <div class="popup_cont">
        <h1>음향설정</h1>
        <table class="tbl4 w450">
          <tbody>
          <tr>
            <th>로비배경음악</th>
            <td>
              <label class="switch-button"> <input type="checkbox" v-model="sound.bg"/> <span
                  class="onoff-switch"></span> </label>
            </td>
          </tr>
          <tr>
            <th>게임사운드</th>
            <td>
              <label class="switch-button"> <input type="checkbox" v-model="sound.effect"/> <span
                  class="onoff-switch"></span> </label>
            </td>
          </tr>
          <!-- <tr>
              <th>생생한 목소리</th>
              <td>
                  <label class="switch-button"> <input type="checkbox" id="chkSndConfigLiveVoice" /> <span
                          class="onoff-switch"></span> </label>
                  <p>라이브 테이블에 들어가면 라이브 음성이 일시적으로 음소거 됩니다.</p>
              </td>
          </tr> -->
          </tbody>
        </table>
      </div>
      <div class="btn_area">
        <button type="button" class="btn btn_sm btn_grd1" @click="closeSoundConfig">취소</button>
        <button type="button" class="btn btn_sm btn_grd1" @click="changeSoundConfig">확인</button>
      </div>
    </div>
  </div>
  <div
      v-show="isPopupVisible"
      class="roadmap_result"
      :style="{ zIndex: 99999999, top: `${popupPosition.top}px`, left: `${popupPosition.left}px`, position: 'absolute' }"
  >
    <p id="round">{{ activeCell?.round || 0 }} 회차</p>
    <ul class="player_result">
      <h2>플레이어 <span>{{ activeCell?.playerResult || 0 }}</span></h2>
      <li v-for="(card, index) in filledCards('player') || []" :key="index"
          :style="{ display: card ? 'block' : 'none' }">
        <div v-if="card" v-html="getPreloadedImageHTML(`/ps/images/card/${card}.png`)"></div>
      </li>
    </ul>
    <ul class="banker_result">
      <h2>뱅커 <span>{{ activeCell?.bankerResult || 0 }}</span></h2>
      <li v-for="(card, index) in filledCards('banker') || []" :key="index"
          :style="{ display: card ? 'block' : 'none' }">
        <div v-if="card" v-html="getPreloadedImageHTML(`/ps/images/card/${card}.png`)"></div>
      </li>
    </ul>
    <h3 v-if="parseInt(activeCell?.result) === 0" class="player win">플레이어 승리</h3>
    <h3 v-if="parseInt(activeCell?.result) === 1" class="banker win">뱅커 승리</h3>
    <h3 v-if="parseInt(activeCell?.result) === 2" class="tie win">타이</h3>
  </div>
</template>

<style scoped>
.blueLineCircle,
.redFullCircle,
.redDiagLine,
.redLineCircle,
.blueFullCircle,
.blueDiagLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px; /* 아이템 간의 간격 조정 */
}

/* li들을 포함한 ul에 flex 적용 */
.blueLineCircle,
.redFullCircle,
.redDiagLine,
.redLineCircle,
.blueFullCircle,
.blueDiagLine {
  display: inline-flex;
}
</style>

<script>
import RadialProgressBarComponent from "@/components/radial-progress-bar-component.vue"
import RoomCardPanelComponent from "@/components/room/room-card-panel-component.vue"
import RoomChipPanelComponent from "@/components/room/room-chip-panel-component.vue"
import RoomHistoryPanelComponent2 from "@/components/room/room-history-panel2-component.vue"
import RoomHistoryPanelComponent from "@/components/room/room-history-panel-component.vue"
import BetHistoryComponent from "@/components/room/bet-history-component.vue"
import {STATE} from "@/helpers/constant.helper"
import {Vue3Marquee} from "vue3-marquee"
import LobbyComponent from "@/components/lobby/lobby-move-component.vue"
import TMutex from "@/libraries/mutex";

export default {
  name: 'room-ps-component',
  props: {},
  components: {
    LobbyComponent,
    RoomHistoryPanelComponent,
    RoomHistoryPanelComponent2,
    RoomChipPanelComponent,
    RoomCardPanelComponent,
    RadialProgressBarComponent,
    Vue3Marquee,
    BetHistoryComponent,
  },
  data() {
    return {
      alert: {
        type: 1,
        msg: '',
        visible: false,
        showBack: true
      },
      mutex: new TMutex(),
      showBetFinishText: false,
      showBetEndingText: false, //베팅 종료 표시를 위한 변수,
      mediaUrl: '',
      user: null,
      room: null,
      company: null,
      companyKey: '',
      firstRequest: true,
      tableKey: '',
      gameLogs: [],
      enableGameLog: false,
      enableSound: true,
      shownResults: [],
      roundResult: {
        p: false,
        b: false,
        t: false,
        pp: false,
        bp: false
      },
      sound: {
        bg: true,
        effect: true
      },
      count: {
        p: 0,
        b: 0,
        t: 0,
        pp: 0,
        bp: 0
      },
      cards: {                                  // 카드
        player: ['', '', ''],
        banker: ['', '', '']
      },
      minmax: null,
      minmaxIndex: 0,
      selectedChipIndex: 0,
      showCardResult: false,                    // 카드 결과 값을 보여줄지 여부
      winAmount: 0,
      totalBet: {                               // 전체 배팅 금액
        p: 0,
        t: 0,
        b: 0,
        pp: 0,
        bp: 0
      },
      bet: {                                    // 완료되지 않은 배팅 금액
        p: 0,
        t: 0,
        b: 0,
        pp: 0,
        bp: 0
      },
      lastBet: {                                // 마지막 배팅 내역
        p: 0,
        t: 0,
        b: 0,
        pp: 0,
        bp: 0
      },
      table: null,
      STATE,
      requestInterval: 1000,
      tmPreview: null,
      showBottomArea: false,
      // windowResizeRatio: opener ? 0.000521 : 0.000485,
      // windowResizeSt: opener ? 1919 : 1921,
      windowResizeRatio: 0.000485,
      windowResizeSt: 1921,
      zoom: 1,
      token: this.$store.getters['auth/token'],
      timerTryBet: null,
      firstPlay: true,
      isCancelRound: false,
      isRetry: false,
      isPopupVisible: false,
      popupPosition: {top: 0, left: 0},
      activeCell: null,
      cardImages: []
    }
  },
  computed: {
    currentGame: function () {
      return this.room.game
    },
    currentRoom: function () {
      return this.room
    },
    currentBetAmount() {
      let amount = 0
      for (const key in this.bet) {
        amount += this.bet[key]
      }

      return amount
    },
    currentTotalBetAmount() {
      let amount = 0
      for (const key in this.totalBet) {
        amount += this.totalBet[key]
      }

      return amount
    }
  },
  methods: {
    preloadImages() {
      const cards = ['c','d','s','h'];
      cards.forEach(card => {
        for(let i = 1; i <= 13; i++){
          const img = new Image();
          img.src = `/ps/images/card/${card + i}.png`;
          this.cardImages.push({ src: img.src, image: img });
        }

      });
    },
    getPreloadedImageHTML(card) {
      const imageObj = this.cardImages.find(img => img.src.includes(card));
      if (imageObj) {
        const imgElement = imageObj.image;
        return imgElement.outerHTML;
      }
      return '';
    },
    showPopup({cell, top, left}) {
      this.activeCell = cell;
      this.isPopupVisible = true;
      this.popupPosition.top = top;
      this.popupPosition.left = left;
    },
    hidePopup() {
      this.isPopupVisible = false;
    },
    filledCards(name) {
      const cards = this.activeCell?.[name] || [];
      const filledCards = [...cards];
      while (filledCards.length < 3) {
        filledCards.push(null);
      }
      return filledCards;
    },
    resizeWindow: function (selector, threshold, ratio) {
      const windowWidth = window.innerWidth;
      const element = document.querySelector(selector);

      element.style.zoom = (windowWidth * ratio).toString();

      /*
      if (windowWidth < threshold) {
        element.style.zoom = (windowWidth * ratio).toString();
      } else {
        element.style.zoom = '1';
      }
       */
    },
    zoomInVideo: function () {
      this.zoom++
      if (this.zoom > 5) {
        this.zoom = 5
      }

      const obj = document.getElementById("video")
      if (obj) {
        obj.className = "";
        obj.classList.add("zoom" + this.zoom);
      }
    },

    zoomOutVideo: function () {
      this.zoom--
      if (this.zoom < 0) {
        this.zoom = 0
      }

      const obj = document.getElementById("video")
      if (obj) {
        obj.className = "";
        obj.classList.add("zoom" + this.zoom);
      }
    },
    openSoundConfig: function () {
      // 음악 체크박스 설정
      const chkSndConfigMusic = document.getElementById('chkSndConfigMusic');
      if (chkSndConfigMusic) {
        chkSndConfigMusic.checked = this.$root.enableMusic;
      }

      // 사운드 체크박스 설정
      const chkSndConfigSound = document.getElementById('chkSndConfigSound');
      if (chkSndConfigSound) {
        chkSndConfigSound.checked = this.$root.enableSound;
      }
      this.$API.util.showPopup('sound_config');
      this.$API.util.openConfigGame()
    },
    closeSoundConfig() {
      this.$API.util.playSoundClick()
      this.$API.util.closePopup('sound_config')
    },
    changeSoundConfig() {
      localStorage.setItem('soundBG', this.sound.bg)
      localStorage.setItem('soundEffect', this.sound.effect)
      this.$API.util.playSoundClick()
      this.$API.util.closePopup('sound_config')

      if (this.sound.bg === true) {
        this.$API.util.playBG()
      }
    },
    setTableMode(a) {
      // 팝업 표시
      this.$API.util.showPopup('set_view');
      this.$refs.config_room_type.init()
      if (!a) this.$API.util.openConfigGame()
    },
    closeRoomMove() {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('move-room-wrapper')
      if (obj) {
        if (this.$refs['move-room-wrapper']) {
          this.$refs['move-room-wrapper'].close()
        }
        obj.style.display = 'none'
      }
    },
    openRoomMove() {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('move-room-wrapper')
      if (obj) {
        if (this.$refs['move-room-wrapper']) {
          this.$refs['move-room-wrapper'].open()
        }
        obj.style.display = 'block'
      }
    },
    closeBets() {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('bet-history')
      if (obj) {
        obj.style.display = 'none'
      }
    },
    openBets() {
      this.$API.util.playSoundClick()

      const obj = document.getElementById('bet-history')
      if (obj) {
        obj.style.display = 'block'
        obj.classList.add('overlay-active')
        setTimeout(() => {
          obj.classList.remove('overlay-active');
        }, 2000); // 3초 동안 배경 이미지 표시
        this.$refs['bet-history'].open()
      }
    },
    toggleBottomArea: function (e) {
      this.$API.util.playSoundClick()

      // this.showBottomArea = !this.showBottomArea
      if (document.querySelector('.bottom_area').style.display !== 'none') {
        e.target.parentElement.querySelector('img').style.transform = 'rotate(180deg)';
        document.querySelector('.table_area').style.width = '20%';
        document.querySelector('.bottom_area').style.display = 'none';
        document.querySelector('.table_area .table_box1').style.width = 'calc(50% - 40px)';
        document.querySelector('.table_area .table_box3').style.width = '50%';
      } else {
        e.target.parentElement.querySelector('img').style.transform = 'rotate(0deg)';
        document.querySelector('.table_area').style.width = '40%';
        document.querySelector('.table_area .table_box1').style.width = 'calc(25% - 40px)';
        document.querySelector('.table_area .table_box3').style.width = '75%';
        document.querySelector('.bottom_area').style.display = 'block';
      }
    },
    showInfo: function (msg) {
      this.showAlert(1, msg, false)
    },
    showResult: function (msg) {
      this.showAlert(3, msg, false)
    },
    showError: function (msg) {
      this.showAlert(2, msg, true);
    },
    showAlert: function (type, msg, showBack = true) {
      this.alert.type = type;
      this.alert.msg = msg;
      this.alert.visible = true;
      this.alert.showBack = showBack
      setTimeout(this.hideAlert, 2000);
    },
    hideAlert: function () {
      this.alert.visible = false;
    },
    adjustZoom() {
      var minWidth = 1800;
      var body = document.getElementsByTagName('body')[0];
      var chkMulti = 0;// document.getElementsByClassName("multi-wrapper").length;

      if (window.innerWidth < minWidth) {
        if (window.innerWidth < 768 && chkMulti < 1) {
          if (window.innerWidth < 320) {
            body.style.zoom = 0.6;
          } else if (window.innerWidth < 400) {
            body.style.zoom = 0.7;
          } else if (window.innerWidth < 480) {
            body.style.zoom = 0.8;
          } else if (window.innerWidth < 768) {
            body.style.zoom = 0.9;
          } else {
            body.style.zoom = 1;
          }
        } else {
          //console.log((window.innerWidth / minWidth));
          body.style.zoom = (window.innerWidth / minWidth);
        }
      } else {
        body.style.zoom = 1;
      }
    },

    toggleLimit: function () {
      if (!this.$API.util.isMobile()) return;
      for (const key in this.refs['game-limit']) {
        const obj = this.refs['game-limit'][key]
        const displayStyle = obj.value.style.display
        obj.value.style.display = displayStyle === 'none' ? '' : 'none'
      }
      // $(this.$el).find("ul.game-limit").toggle();
    },
    renderPreview(res) {
      this.$API.util.playSoundClick()
      if (this.$refs['room-history-panel-component']) this.$refs['room-history-panel-component'].renderPreviewHistory(res)
    },
    videoZoom: function () {
      this.$API.util.playSoundClick()
    },
    soundOnOff: function () {

      this.$API.util.playSoundClick()

      this.enableSound = !this.enableSound

      if (this.enableSound === true) {
        localStorage.setItem('soundEffect', 'true')
      } else {
        localStorage.setItem('soundEffect', 'false')
      }

      // this.videoPlayer.enableAudio(this.enableSound)
      // this.mediaUrl = `/game/video?token=${this.token}&companyKey=${this.companyKey}&tableKey=${this.tableKey}&serverUrl=${this.room.data.vodMain}&sound=${(this.enableSound === true) ? 'on' : 'off'}`

      const iframe = document.querySelector('iframe');
      iframe.contentWindow.postMessage(this.enableSound ? 'muteOff' : 'muteOn', '*');
      this.showResult(this.enableSound ? '음소거 해제<br/>소리가 나옵니다' : '음소거<br/>소리가 나지 않습니다');
    },
    gameLogOnOff: function () {
      this.$API.util.playSoundClick()

      this.enableGameLog = !this.enableGameLog;
      if (this.enableGameLog) {
        document.querySelectorAll('.game-log-container').forEach(function (el) {
          el.style.display = 'block'
        })
      } else {
        document.querySelectorAll('.game-log-container').forEach(function (el) {
          el.style.display = 'none'
        })
      }
    },
    setCardResult(cards) {
      // {\"playerCards\":[{\"shape\":\"h\",\"number\":5},{\"shape\":\"d\",\"number\":1}],\"bankerCards\":[{\"shape\":\"s\",\"number\":1},{\"shape\":\"s\",\"number\":6}]}

      this.cards.player = ['', '', '']
      this.cards.banker = ['', '', '']

      let playerCards = this.cards.player
      let bankerCards = this.cards.banker

      for (const key in cards.playerCards) {
        let card = cards.playerCards[key]
        playerCards[key] = card.shape + card.number
      }

      for (const key in cards.bankerCards) {
        let card = cards.bankerCards[key]
        bankerCards[key] = card.shape + card.number
      }
    },
    // 방에서 결과 나올때 호출
    renderRoundResult: function (cards, roundResult) {
      this.noBetCount++;
      /*
      if (this.noBetCount > 5) {
        // _app.showInfo(CasinoConfig.NoBetLimit + "판동안 베팅이 없으셔서 로비로 이동됩니다.");
        setTimeout(this.goLobby, 2000);
      }
       */

      this.setCardResult(cards);

      const playerNumber = roundResult.playerNumber;
      const bankerNumber = roundResult.bankerNumber;

      this.showCardResult = false;

      const result = {
        p: false,
        t: false,
        b: false,
        pp: false,
        bp: false
      };

      if (roundResult.result === '10') {
        result.b = true;
      } else if (roundResult.result === '11') {
        result.b = true;
        result.bp = true;
      } else if (roundResult.result === '12') {
        result.b = true;
        result.pp = true;
      } else if (roundResult.result === '13') {
        result.b = true;
        result.pp = true;
        result.bp = true;
      } else if (roundResult.result === '20') {
        result.t = true;
      } else if (roundResult.result === '21') {
        result.t = true;
        result.bp = true;
      } else if (roundResult.result === '22') {
        result.t = true;
        result.pp = true;
      } else if (roundResult.result === '23') {
        result.t = true;
        result.pp = true;
        result.bp = true;
      } else if (roundResult.result === '30') {
        result.p = true;
      } else if (roundResult.result === '31') {
        result.p = true;
        result.bp = true;
      } else if (roundResult.result === '32') {
        result.p = true;
        result.pp = true;
      } else if (roundResult.result === '33') {
        result.p = true;
        result.bp = true;
        result.pp = true;
      }

      this.roundResult = result;

      if (result.p === true) {
        this.$API.util.playSoundPlayer();
      } else if (result.b === true) {
        this.$API.util.playSoundBanker();
      } else if (result.t === true) {
        this.$API.util.playSoundTie();
      }

      if (this.$API.util.isMobile() === true) {
        if (this.$refs['room-card-panel-mobile']) {
          this.$refs['room-card-panel-mobile'].setRoundResult({
            result,
            cards: this.cards,
            playerNumber,
            bankerNumber
          });
        }
      } else {
        if (this.$refs['room-card-panel']) {
          this.$refs['room-card-panel'].setRoundResult({
            result,
            cards: this.cards,
            playerNumber,
            bankerNumber
          });
        }
      }

      this.resultTimeout = setTimeout(() => {
        this.showFinalResult();
      }, 1500);
    },


    showFinalResult: function () {
      console.log("모든 이미지가 로드되었습니다. 최종 결과를 표시합니다.");
      clearTimeout(this.resultTimeout);
      this.showCardResult = true;
    },

    goLobby() {
      this.$API.util.playSoundClick()

      document.location.href = `/${this.companyKey}?token=${this.$store.getters['auth/token']}`
    },

    initRoomState: function () {
      for (let i = 0; i < 6; i++) {
        let chipElement = document.querySelector("#betChips td.ui_chip" + (i + 1));
        if (chipElement) {
          chipElement.innerHTML = this.getChipMoneyUnit(this.chips[i]);
        }
      }

      this.noBetCount = 0;

      this.currentRoom.historyCards = [];

      let quickNoticePre = document.querySelector(".quick-notice-pre");
      let noticeWidth = (quickNoticePre ? quickNoticePre.offsetWidth : 0) + window.innerWidth + 100;
      let quickNoticeDiv = document.querySelector(".quick-notice div div");
      if (quickNoticeDiv) {
        quickNoticeDiv.style.width = noticeWidth + 'px';
        quickNoticeDiv.style.animationDuration = (noticeWidth / 50) + 's';
      }
    },

    //--------------------------------
    async myInfo() {
      try {
        const result = await this.$API.game.myInfo(this.room && this.room.game ? this.room.game.roundId : '')
        if (result.code !== 'OK') {
          throw result
        }

        // 이전 배팅 금액 확인
        for (const key in result.bets) {
          const bet = result.bets[key]
          this.totalBet[bet.target] += bet.amount
        }

        this.user = result.user
        this.$store.commit('auth/SET_CURRENT_USER', result.user)

        return true
      } catch (e) {
        this.$log.error(e)
        return false
      }
    },

    async getTable() {
      const self = this
      try {
        const result = await this.$API.game.table(this.companyKey, this.tableKey)
        if (result.code !== 'OK') {
          this.showError(result.message)
          return
        }

        this.room = result.table
        this.minmax = this.room.minmax[this.minmaxIndex]
        if (this.room.game) this.room.game = JSON.parse(this.room.game)
        this.company = result.company

        this.mediaUrl = `/game/video?token=${this.token}&companyKey=${this.companyKey}&tableKey=${this.tableKey}&serverUrl=${this.room.data.vodMain}&sound=on`

        setTimeout(() => {
          self.getStatus().then()
        }, 1000)

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async cancelBets() {
      if (this.room.game.state !== 'betting' && this.room.game.state !== 'nomorebet') {
        return
      }
      if (this.isRetry) {
        this.isRetry = false
      }
      this.isCancelRound = true
      this.resetBet()
    },

    // 아래는 실제 취소 부분
    async cancelBet() {
      const mutex = await this.mutex.acquire('bet')
      try {
        this.$API.util.playSoundClick()

        if (this.currentTotalBetAmount <= 0) {
          return
        }

        if (!this.room || !this.room.game || !this.room.game.roundId) {
          // this.$refs['modal_error'].open({ title: '배팅 오류', content: '테이블 또는 라운드 정보가 존재하지 않습니다.' })
          this.showError('테이블 또는 라운드 정보가 존재하지 않습니다.')
          return
        }

        if (this.room.game.state !== 'betting' && this.room.game.state !== 'nomorebet') {
          this.showError('베팅 가능한 생태가 아닙니다.')
          return
        }

        // const loader = this.$loading.show({ container: this.$refs.betPanel })
        try {
          const result = await this.$API.game.cancel(this.company.key, this.room.key, this.room.game.roundId, this.minmaxIndex, this.bet)
          if (result.code !== 'OK') {
            // this.$refs['modal_error'].open({ title: result.code, content: result.message })
            this.showError(result.message)
            return
          }

          // 배팅 상태 초기화
          this.resetTotalBet()

          this.$store.commit('auth/SET_CASH', result.balance)

          // this.$API.util.playSoundBet()

        } catch (e) {
          // this.$refs['modal_error'].open({ title: 'EXCEPTION', content: e.message })
          this.showError(e.message)
        }// finally {
        //  loader.hide()
        //}
      } finally {
        await this.mutex.release('bet', mutex)
      }
    },

    async addBet(target) {
      this.$API.util.playSoundClick()


      const mutex = await this.mutex.acquire('bet')
      if (!mutex) {
        this.showError('배팅 도중 오류가 발생하였습니다. 고객센터에 문의하여 주세요.')
      }

      try {
        if (this.room.game.state !== 'betting' && this.room.game.state !== 'nomorebet') {
          return
        }

        if (this.firstPlay === true) {
          this.resetBet()
          this.showInfo('입장 후 첫 베팅은 할 수 없습니다.')
          return
        }

        this.isCancelRound = false

        let amount = this.minmax.chips[this.selectedChipIndex]
        const userCash = this.user.cash - this.currentBetAmount
        if (userCash <= amount) {
          amount = userCash
        }

        if (amount <= 0) {
          // this.showInfo('보유금액을 확인하여 주세요.')
          return
        }

        const sumAmount = this.bet[target] + amount

        if (amount > 0) {
          let title = ''
          if (target === 'p') title = '플레이어'
          else if (target === 't') title = '타이'
          else if (target === 'b') title = '뱅커'
          else if (target === 'pp') title = '플레이어 페어'
          else if (target === 'bp') title = '뱅커 페어'

          let t = ''
          if (target === 'p' || target === 'b') t = 'pb'
          else if (target === 't') t = 't'
          else if (target === 'pp' || target === 'bp') t = 'pair'


          // 자동으로 최소 베팅금액으로 올릴 예정
          if (sumAmount < this.room.minmax[this.minmaxIndex][t][0]) {
            amount = this.room.minmax[this.minmaxIndex][t][0]
          }

          if (sumAmount > this.room.minmax[this.minmaxIndex][t][1]) {
            return this.showInfo(`${title} 최대 배팅금액은 ${this.room.minmax[this.minmaxIndex][t][1].toThousands()}원입니다.`)
          }
        }

        this.bet[target] += amount

      } finally {
        await this.mutex.release('bet', mutex)
      }
    },

    async tryBet() {
      // const loader = this.$loading.show({ container: this.$refs.betPanel })
      try {
        this.$API.util.playSoundClick()

        if (this.currentBetAmount <= 0) {
          // this.$refs['modal_error'].open({ title: '배팅 오류', content: '배팅할 영역을 선택하여 주세요.' })
          // alert('배팅할 영역을 선택하여 주세요.')
          this.resetBet()
          return
        }

        if (!this.room || !this.room.game || !this.room.game.roundId) {
          // this.$refs['modal_error'].open({ title: '배팅 오류', content: '테이블 또는 라운드 정보가 존재하지 않습니다.' })
          this.resetBet()
          this.showError('테이블 또는 라운드 정보가 존재하지 않습니다.')
          return
        }

        /*
        if (this.room.game.state !== 'betting' && this.room.game.state !== 'nomorebet') {
          this.resetBet()
          alert('베팅 가능한 생태가 아닙니다.')
          return
        }
         */

        if (this.currentBetAmount > this.user.cash) {
          this.resetBet()
          this.showInfo('배팅금액이 보유금액을 초과하였습니다.')
          return
        }

        const result = await this.$API.game.bet(this.company.key, this.room.key, this.room.game.roundId, this.minmaxIndex, this.bet)
        if (result.code !== 'OK') {
          // this.$refs['modal_error'].open({ title: result.code, content: result.message })

          this.resetBet()
          this.showError(result.message)
          return
        }

        for (const key in this.bet) {
          this.totalBet[key] += this.bet[key]
          this.bet[key] = 0
        }

        this.$store.commit('auth/SET_CASH', result.balance)

        // this.$API.util.playSoundBet()

      } catch (e) {
        // this.$refs['modal_error'].open({ title: 'EXCEPTION', content: e.message })
        this.resetBet()
        this.showError(e.message)
      }// finally {
      //  loader.hide()
      //}
    },
    /**
     * 배팅을 하였다면 결과를 확인한다.
     * @returns {Promise<void>}
     */
    async checkBetResult(totalBetAmount, round) {
      const self = this
      let checkResult = true

      if (!this.room || !this.room.game || !this.room.game.roundId) {
        // this.$refs['modal_error'].open({ title: '배팅 오류', content: '테이블 또는 라운드 정보가 존재하지 않습니다.' })
        this.showError('테이블 또는 라운드 정보가 존재하지 않습니다.')
        return
      }

      try {
        const result = await this.$API.game.result(this.company.key, this.room.key, round)
        if (result.code !== 'OK') {
          if (result.code !== 'NOT_COMPLETE') {
            // this.$refs['modal_error'].open({title: result.code, content: result.message})
            console.log('심리스 안끝남')
          }
          return
        }

        checkResult = false

        let totalAmount = 0

        for (const key in result.bets) {
          const bet = result.bets[key]
          const amount = (bet.payout - bet.amount)
          totalAmount += amount
        }

        this.winAmount = totalAmount
        this.showResult((totalAmount >= 0 ? "당첨금액 : +" : "손실금액 : ") + (totalAmount === 0 ? totalAmount : this.$API.util.getFormattedMoney(totalAmount)))

        this.$store.commit('auth/SET_CASH', result.balance)

      } catch (e) {
        // this.$refs['modal_error'].open({ title: 'EXCEPTION', content: e.message })
        this.showError(e.message)
      } finally {
        if (checkResult === true) {
          setTimeout(() => {
            self.checkBetResult(totalBetAmount, round).then()
          }, 2000)
        }
      }

    },
    async retryAddBet() {
      if (this.isRetry) {
        return
      }


      if (Object.values(this.lastBet).filter(e => e > 0).length === 0) {
        this.showResult('알림<br/>전판에 베팅한 기록이 없습니다.');
        return
      }
      const mutex = await this.mutex.acquire('bet')

      try {
        this.$API.util.playSoundClick()

        this.$log.debug('다시배팅:', this.lastBet)

        for (const key in this.lastBet) {
          if (this.lastBet[key] > 0) {
            this.bet[key] = this.lastBet[key];
          }
        }
        this.isRetry = true
        // await this.tryBet()
      } finally {
        await this.mutex.release('bet', mutex)
      }
    },
    changeChipIndex(idx) {
      this.$API.util.playSoundChip()

      this.selectedChipIndex = idx
    },
    async getStatus() {
      const self = this
      if (this.$route.path.includes('/error')) {
        this.$log.debug('URL에 /error가 포함되어 있어 요청을 보내지 않습니다.');
        return;
      }
      try {
        const tables = [{key: this.room.key, beginPosition: this.room.game ? this.room.game.historyLength : 0}]

        const result = await this.$API.game.status(this.companyKey, tables)
        if (result.code !== 'OK') {
          // this.$refs['modal_error'].open({ title: result.code, content: result.message })
          this.showError(result.message)
          return
        }

        const table = result.tables[this.room.key]
        if (!table) {
          this.$log.error(this.room.key + ' 테이블 데이터가 존재하지 않습니다.')
          return
        }
        table.game = JSON.parse(table.game)

        // 슈체인지 이후 배팅 상태가 되면 출목을 초기화 한다.
        if (this.room && this.room.game) {
          if (this.room.game.state === this.STATE.SHOE_CHANGE && table.game.state === this.STATE.BETTING) {
            this.room.histories = []
            if (this.$refs['room-history-panel-component']) {
              this.$refs['room-history-panel-component'].updateHistory()
              this.count = this.$refs['room-history-panel-component'].getHistoryCount()
            }

          }
        }

        // 출목 추가
        for (const idx in table.detail) {
          if (this.room.histories.length < table.game.historyLength && table.detail) {
            const h = table.history[idx]
            const d = table.detail[idx]
            this.room.histories.push(h)
            this.room.detail.push(d)
            if (this.$refs['room-history-panel-component']) {
              this.$refs['room-history-panel-component'].updateHistory()
            }
          }
        }

        if (this.$refs['room-history-panel-component']) {
          this.count = this.$refs['room-history-panel-component'].getHistoryCount()
        }

        if (table.game.state === this.STATE.BETTING) {
          this.requestInterval = (table.game.second - 5) * 1000
          if (this.requestInterval <= 0) {
            this.requestInterval = table.game.second * 1000
          }
        }

        if (table.game.state === this.STATE.NO_MORE_BET) {
          this.requestInterval = table.game.second * 1000
        }

        if (table.game.state === this.STATE.STOP) {
          this.requestInterval = 1000
        }

        if (table.game.state === this.STATE.CLOSE) {
          this.requestInterval = 1000
        }

        if (table.game.state === this.STATE.SHOE_CHANGE) {
          this.requestInterval = 5000
        }

        if (this.requestInterval <= 0) {
          this.requestInterval = 2000
        }

        /*
        if (table.game.state === this.STATE.BETTING && this.firstRequest === true) {
          this.room.game.state = STATE.BETTING
          setTimeout(() => {
            if (self.$refs['radial-progress']) {
              self.$refs['radial-progress'].initTimerState()
              self.$refs['radial-progress'].onStartBet(self.room.game.second - 3) // 3초 전에 종료시킨다.
            }
          }, 100)
        }
         */

        // 배팅
        if (table.game.state === this.STATE.BETTING && this.room.game.state !== this.STATE.BETTING) {

          this.showBetFinishText = false

          // clearTimeout(this.timerTryBet)

          this.$log.debug('배팅시작')

          this.$API.util.playSoundBetStart()

          self.initBet()

          /*
          setTimeout(() => {
            self.roundResult = {
              p: false,
              b: false,
              t: false,
              pp: false,
              bp: false
            }

            self.showCardResult = false

          }, 2000)


          // 배팅 시작
          for(const key in this.totalBet) {
            this.totalBet[key] = 0
          }

          for(const key in this.bet) {
            this.bet[key] = 0
          }
           */

          this.room.game.second = table.game.second
          this.room.game.state = STATE.BETTING
          let interval = this.room.game.second - 3
          if (interval < 0) {
            interval = 0
          }

          setTimeout(() => {
            self.$refs['radial-progress'].initTimerState()
            self.$refs['radial-progress'].onStartBet(interval)
          }, 100)

          // 3초전에 미리 배팅을 중지하고 배팅을 시도한다.
          setTimeout(() => {
            self.$log.debug('배팅중지---')
            this.$API.util.playSoundBetEnd()
            this.showBetEndingText = true;
            this.showBetFinishText = false;

            setTimeout(() => {
              this.showBetEndingText = false;
              this.showBetFinishText = true;
            }, 2000);
            self.room.game.state = STATE.STOP
            if (self.currentBetAmount > 0) {
              for (const key in self.bet) {
                self.lastBet[key] = self.bet[key]
              }

              self.tryBet().then()
            }
          }, interval * 1000)

          // 배팅 중지
        } else if (table.game.state === this.STATE.NO_MORE_BET && this.room.game.state !== this.STATE.NO_MORE_BET) {
          this.$log.debug('배팅중지')
          // this.$API.util.playSoundBetEnd()
          this.showBetFinishText = true

          if (this.currentTotalBetAmount > 0) {
            for (const key in this.totalBet) {
              this.lastBet[key] = this.totalBet[key]
            }
          }

          this.$log.debug('stop last bet:', this.currentTotalBetAmount, this.totalBet, this.lastBet)

          // 카드 오픈
        } else if (table.game.state === this.STATE.STOP && this.room.game.state !== this.STATE.STOP) {
          this.showBetFinishText = true
          this.$log.debug('카드오픈')
          // 결과
        } else if (table.game.state === this.STATE.CLOSE && this.room.game.state !== this.STATE.CLOSE) {
          this.$log.debug('결과확인')

          // 1초 뒤에 결과값 확인
          const ri = table.game.roundId
          const totalBetAmount = this.currentTotalBetAmount
          if (this.currentTotalBetAmount > 0) {
            setTimeout(() => {
              self.checkBetResult(totalBetAmount, ri).then()
            }, 1000)
          }


          // 결과 출력(카드)
          if (table.game.result.result) {
            this.showBetFinishText = false
            this.renderRoundResult(table.game.cards, table.game.result)

            setTimeout(() => {

              // 배팅 초기화
              self.initBet()

              // 결과 초기화
              self.roundResult = {
                p: false,
                b: false,
                t: false,
                pp: false,
                bp: false
              }

              // 결과 카드 숨김
              self.showCardResult = false
            }, 3000)
          }

          this.firstPlay = false

          // 슈박스 체인지
        } else if (table.game.state === this.STATE.SHOE_CHANGE && this.room.game.state !== this.STATE.SHOE_CHANGE) {
          this.$log.debug('슈체인지')

          this.firstPlay = false
        }

        this.room.game = table.game

      } catch (e) {
        this.$log.error(e)
      } finally {
        this.firstRequest = false
        setTimeout(() => {
          self.getStatus().then()
        }, self.requestInterval)
      }
    },
    async restartVideo() {
      this.mediaUrl = `/game/video?token=${this.token}&companyKey=${this.companyKey}&tableKey=${this.tableKey}&serverUrl=${this.room.data.vodMain}&sound=on&_t=${new Date().getTime()}`
    },
    initBet() {
      for (const key in this.totalBet) {
        this.totalBet[key] = 0
      }

      for (const key in this.bet) {
        this.bet[key] = 0
      }
      this.isCancelRound = false
      this.isRetry = false
      this.winAmount = 0
    },
    resetTotalBet() {
      for (const key in this.totalBet) {
        this.totalBet[key] = 0
      }

      this.resetBet()
    },
    resetBet() {
      for (const key in this.bet) {
        this.bet[key] = 0
      }
    },
    async init() {
      this.companyKey = this.$route.params.companyKey
      this.tableKey = this.$route.params.tableKey

      if (!this.companyKey) {
        this.showError('회사 코드를 확인하세요.')
        history.go(-1)
        return
      }

      if (!this.tableKey) {
        this.showError('테이블 코드를 확인하세요.')
        history.go(-1)
        return
      }

      await this.getTable()
      await this.myInfo()

      const us = new URL(window.location.href)
      this.minmaxIndex = us.searchParams.get('minmax') ? us.searchParams.get('minmax').toFloat() : 0

      /*
      this.adjustZoom()
      window.addEventListener('resize', this.adjustZoom);
      window.addEventListener('DOMContentLoaded', this.adjustZoom);
       */

      if (!this.$API.util.isMobile()) {
        const self = this

        this.resizeWindow("section.main", this.windowResizeSt, this.windowResizeRatio);
        window.addEventListener('resize', function () {
          self.resizeWindow("section.main", self.windowResizeSt, self.windowResizeRatio);
        });
        window.addEventListener('DOMContentLoaded', function () {
          self.resizeWindow("section.main", self.windowResizeSt, self.windowResizeRatio);
        });
      }

      this.initRoomState()
    }
  },
  mounted: function () {

    const self = this
    this.init()
    setTimeout(() => {
      if (self.$refs['room-history-panel-component']) {
        self.count = self.$refs['room-history-panel-component'].getHistoryCount()
      }
    }, 500)

    const bg = localStorage.getItem('soundBG')
    if (bg === 'true') {
      this.sound.bg = true
    } else {
      if (!bg) {
        this.sound.bg = true
      } else {
        this.sound.bg = false
      }
    }

    const a = localStorage.getItem('soundEffect')
    this.enableSound = a === 'true'

    const effect = localStorage.getItem('soundEffect')
    if (effect === 'true') {
      this.sound.effect = true
    } else {
      if (!effect) {
        this.sound.effect = true
      } else {
        this.sound.effect = false
      }
    }
  },
  created: function () {
    this.preloadImages()
  },
  unmounted: function () {

  }
}
</script>