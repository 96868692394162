<template>
  <div class="history-123-drawer" :style="containerStyle" ref="drawerContainer">
    <div class="drawer-content" :style="contentStyle">
      <svg class="lines-svg" :width="fullWidth" :height="containerStyle.height">
        <line v-for="(line, index) in lines" :key="index"
              :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2"
              stroke="#ddd" stroke-width="1" />
      </svg>
      <div v-for="(col, colIndex) in drawCells" :key="colIndex" class="column">
        <div v-for="(cell, rowIndex) in col" :key="rowIndex" class="cell" :style="cellStyle">
          <img v-if="cell" :src="getImagePath(cell.result)" :alt="getAltText(cell.result)" :style="imageStyle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const BREAK_LEFTORRIGHT = 1;
const RESULT_PLAYER = 0;
const RESULT_BANKER = 1;
const RESULT_TIE = 2;

export default {
  name: 'History123Drawer',
  props: {
    drawType: {
      type: Number,
      required: true,
      validator: (value) => [1, 2, 3].includes(value)
    },
    reloadInterval: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      drawCells: [],
      calcCells: [],
      cols: 0,
      rows: 6,
      cellWidth: 0,
      cellHeight: 0,
      deltaCols: 0,
      breakLeftOrRight: BREAK_LEFTORRIGHT,
      lines: [],
      fullWidth: 0,
      showBase: 0,

      reloadTimer: null
    }
  },
  mounted() {
    this.startReloadTimer()
  },
  beforeUnmount() {
    this.stopReloadTimer()
  },
  computed: {
    containerStyle() {
      return {
        width: `${this.cols * this.cellWidth}px`,
        height: `${this.rows * this.cellHeight}px`,
      }
    },
    contentStyle() {
      return {
        width: `${this.fullWidth}px`,
        height: `${this.rows * this.cellHeight}px`,
      }
    },
    cellStyle() {
      return {
        width: `${this.cellWidth}px`,
        height: `${this.cellHeight}px`,
      }
    },
    imageStyle() {
      return {
        width: '100%',
        height: '100%',
      }
    }
  },
  methods: {
    init(width, height, cols) {
      this.cols = cols;
      this.cellWidth = width / this.cols;
      this.cellHeight = height / this.rows;
      this.drawCells = Array(this.cols).fill().map(() => Array(this.rows).fill(null));
      this.fullWidth = width;
      this.showBase = this.drawType;
      this.generateLines();
    },
    updateCells(origins) {
      let curPos = { col: 0, row: 0 };
      this.calcCells = [];
      let oldCell = null;

      for (let iCol = this.showBase + ((origins.length > 0 && origins[0].length > 0 && origins[0][0].result == RESULT_TIE) ? 1 : 0); iCol < origins.length; iCol++) {
        let iRow = 0;
        do {
          let baseLink = false;
          if (origins[iCol - this.showBase] !== undefined && origins[iCol - this.showBase][iRow] !== undefined) {
            if (origins[iCol - this.showBase][iRow + 1] !== undefined) baseLink = true;
          } else {
            baseLink = true;
          }

          let link = false;
          if (origins.length <= iCol + 1 && origins[iCol][iRow + 1] === undefined) break;
          if (origins[iCol][iRow + 1] !== undefined) link = true;

          let iResult = baseLink == link ? RESULT_BANKER : RESULT_PLAYER;
          if (oldCell != null) {
            if (oldCell.result == iResult) {
              curPos.row++;
            } else {
              curPos.col++;
              curPos.row = 0;
            }
          }
          if (this.calcCells[curPos.col] === undefined) this.calcCells[curPos.col] = [];
          oldCell = this.calcCells[curPos.col][curPos.row] = { result: iResult };
          iRow++;
        } while (origins[iCol][iRow] != undefined);
      }
    },
    redraw(origins) {
      this.updateCells(origins);
      this.drawCells = [];
      this.deltaCols = 0;

      let iPrevResult = -1;
      let curPos = { row: 0, col: 0 };
      for (let iOrgCol = 0; iOrgCol < this.calcCells.length; iOrgCol++) {
        for (let iOrgRow = 0; iOrgRow < this.calcCells[iOrgCol].length; iOrgRow++) {
          if (iPrevResult >= 0) {
            this.getNextRowCol(iPrevResult == this.calcCells[iOrgCol][iOrgRow].result, curPos);
          }
          if (this.drawCells[curPos.col] === undefined) this.drawCells[curPos.col] = [];
          this.drawCells[curPos.col][curPos.row] = this.calcCells[iOrgCol][iOrgRow];
          iPrevResult = this.calcCells[iOrgCol][iOrgRow].result;
        }
      }

      const drawnWidth = this.drawCells.length * this.cellWidth;
      this.fullWidth = Math.max(drawnWidth, this.cols * this.cellWidth);
      this.generateLines();

      this.$nextTick(() => {
        const container = this.$refs.drawerContainer;
        container.scrollLeft = container.scrollWidth;
      });
    },
    generateLines() {
      this.lines = [];
      const totalCols = Math.ceil(this.fullWidth / this.cellWidth);

      // 수직선
      for (let col = 0; col <= totalCols + 6; col++) {
        this.lines.push({
          x1: col * this.cellWidth,
          y1: 0,
          x2: col * this.cellWidth,
          y2: this.rows * this.cellHeight
        });
      }

      // 수평선
      for (let row = 0; row <= this.rows; row++) {
        this.lines.push({
          x1: 0,
          y1: row * this.cellHeight,
          x2: this.fullWidth,
          y2: row * this.cellHeight
        });
      }
    },
    getNextRowCol(tf, pos) {
      if (tf) {
        pos.row++;
        if (pos.row >= this.rows || this.drawCells[pos.col]?.[pos.row] !== undefined || this.deltaCols > 0) {
          pos.row--;
          pos.col += this.breakLeftOrRight;
          this.deltaCols++;
          if (pos.col < 1 || (this.drawCells[pos.col] !== undefined && this.drawCells[pos.col][pos.row] !== undefined) ||
              (this.drawCells[pos.col + this.breakLeftOrRight] !== undefined && this.drawCells[pos.col + this.breakLeftOrRight][pos.row] !== undefined)) {
            for (let i = 1; i < this.deltaCols; i++) {
              const cell = this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row];
              if (this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] === undefined) {
                this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight] = [];
              }
              this.drawCells[pos.col - this.deltaCols * this.breakLeftOrRight][pos.row] = cell;
              this.drawCells[pos.col - i * this.breakLeftOrRight][pos.row] = undefined;
            }
            this.breakLeftOrRight = this.breakLeftOrRight == 1 ? -1 : 1;
            pos.col += 2 * this.deltaCols * this.breakLeftOrRight;
          }
        }
      } else {
        pos.col = pos.col - this.breakLeftOrRight * this.deltaCols + 1;
        pos.row = 0;
        this.deltaCols = 0;
        this.breakLeftOrRight = BREAK_LEFTORRIGHT;
      }
    },
    getImagePath(result) {
      const iconNumber = this.drawType * 2 - (result === RESULT_PLAYER ? 1 : 0);
      return `/imgs/history/icon_s${iconNumber}.png`;
    },
    getAltText(result) {
      return result === RESULT_PLAYER ? 'Player' : 'Banker';
    },

    // [이미지 로딩 실패 시 리로드]
    startReloadTimer() {
      this.reloadTimer = setInterval(() => {
        this.reloadBrokenImages()
      }, this.reloadInterval)
    },
    stopReloadTimer() {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer)
      }
    },
    reloadBrokenImages() {
      if (!this.$refs.drawerContainer) return

      const images = this.$refs.drawerContainer.getElementsByTagName('img')
      for (let img of images) {
        if (!img.complete) {
          // 이미지가 로드되지 않았거나 너비가 0인 경우 (로딩 실패)
          const currentSrc = img.src
          img.src = ''
          img.src = currentSrc
          console.log('Reloading image:', currentSrc)
        }
      }
    }
  }
}
</script>

<style scoped>
.history-123-drawer {
  overflow-x: auto;
  position: relative;
}
.drawer-content {
  display: flex;
  position: relative;
}
.lines-svg {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.column {
  display: flex;
  flex-direction: column;
}
.cell {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1px;
}
img {
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
}
</style>

