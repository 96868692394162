<template>
  <div class="clean-grid-grid-post">
    <div class="grid-title" style="text-align: left;">

      <img :src="`/imgs/${company.key}.png`" class="game_box_img" style="width: 100px;">

      <button type="button" class="game-room-btn title-txt floatRight m_r10" v-if="room" style="margin-right: 10px;">
        {{ room.name }} &nbsp; &nbsp;

      </button>

    </div>
    <div class="grid-cont" @mouseenter="onLobbyRoomOver" @mouseleave="onLobbyRoomOut" @click="enterRoom">
      <div class="game-pad" v-if="room && room.game && room.game.state !== 'maintenance'">
        <lobby-history-panel-component :lobbyColType="lobbyColType" :lobbyHistoryType="lobbyHistoryType" :room="room" :histories="room.histories" :ref="'lobby-history-panel-' + room._id" />
      </div>
      <div class="game-off" v-show="!room || !room.game || room.game.state === 'maintenance' || room.game.state === 'shoechange'">
        <div class="game-off-box">
          <div class="game-off-inbox">
            <h1>게임에 참여하실 수 없습니다.</h1>
            <p>다른 방을 이용해 주시기 바랍니다. 감사합니다.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-tail">
      <div class="floatLeft m_b10">
        <img src="/resources/img/icon-player.png" style="width: 22px;"> <span id="" class="p-txt" style="margin-right: 10px;">{{ count.p }}</span>
        <img src="/resources/img/icon-banker.png" style="width: 22px;"> <span id="" class="b-txt" style="margin-right: 10px;">{{ count.b }}</span>
        <img src="/resources/img/icon-tie.png" style="width: 22px;"> <span id="" class="t-txt">{{ count.t }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import LobbyHistoryPanelComponent from "@/components/lobby/lobby-history-panel-component2.vue"
import { STATE } from "@/helpers/constant.helper"

export default {
  props: {
    lobbyColType: Number,
    lobbyHistoryType: Number,
    lobbyRoom: Object,
    company: Object
  },
  components: {
    LobbyHistoryPanelComponent
  },
  data () {
    return {
      STATE,
      room: null,
      isMouseOver: false,
      count: {
        p: 0,
        b: 0,
        t: 0
      },

    }
  },
  methods: {
    onLobbyRoomOver: function () {
      if (this.$API.util.isMobile()) return;
      if (this.room.state === 'maintenance') return;
      this.isMouseOver = true
    },
    onLobbyRoomOut: function () {
      if (this.$API.util.isMobile()) return;
      this.isMouseOver = false
    },
    enterRoom () {
      if (this.room.state === 'maintenance') return

      this.$API.util.playSoundClick()

      let roomType = localStorage.getItem('roomType')
      if (!roomType) roomType = '1'

      if (roomType === '1') {
        document.location.href=`/game/${this.company.key}/${this.room.key}?token=${this.$store.getters['auth/token']}&minmax=0`
      } else {
        document.location.href=`/game/${this.company.key}/${this.room.key}/wide?token=${this.$store.getters['auth/token']}&minmax=0`
      }
    },
    updateHistory() {
      const self = this
      setTimeout(() => {
        if (self.$refs['lobby-history-panel-' + self.room._id]) {
          self.$refs['lobby-history-panel-' + self.room._id].updateHistory()
          self.count = self.$refs['lobby-history-panel-' + self.room._id].getHistoryCount()
        }
      }, 100)
    },
    setState (state) {
      if (this.room && this.room.game) {
        // 슈체인지 이후 배팅 상태가 되면 출목을 초기화 한다.
        if (this.room.game.state === this.STATE.SHOE_CHANGE && state === this.STATE.BETTING) {
          this.room.histories = []
          this.room.game.historyLength = 0
          this.updateHistory()
        }

        if (this.room.game.state !== state) {
          this.room.game.state = state
        }
      }
    },
    setSecond () {

    }
  },
  mounted() {
    const self = this
    self.room = self.lobbyRoom
    setTimeout(() => {
      if (self.lobbyRoom) {
        self.updateHistory()
        if (self.$refs['lobby-history-panel-' + self.room._id]) {
          self.count = self.$refs['lobby-history-panel-' + self.room._id].getHistoryCount()
        }
      }
    }, 500)
  }
}
</script>