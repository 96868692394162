<template>
  <div class="history-six-drawer" :style="containerStyle" ref="drawerContainer" style="width:620px">
    <div class="drawer-content" :style="contentStyle" style="width:620px">
      <div v-for="(col, colIndex) in drawCells" :key="colIndex" class="column" style="width:620px">
        <div v-for="(cell, rowIndex) in col" :key="rowIndex" class="cell" :style="cellStyle">
          <!-- 이미지 -->
          <img
              v-if="cell"
              :src="getImagePath(cell.hist)"
              :alt="getAltText(cell.result)"
              @mouseover="onMouseOver(cell, $event)"
              @mouseleave="onMouseLeave"
              style="width: 51px; height: 47px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const TEXT_PLAYER = "P";
const TEXT_BANKER = "B";
const TEXT_TIE = "T"

export default {
  name: 'HistorySixDrawer',
  props: {
    reloadInterval: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      drawCells: [],
      pCount: 0,
      bCount: 0,
      tCount: 0,
      ppCount: 0,
      bpCount: 0,
      cols: 0,
      rows: 6,
      cellWidth: 0,
      cellHeight: 0,
      popupPosition:{top:0,left:0},
      isPopupVisible: false,
      activeCell: null,

      reloadTimer: null
    }
  },
  mounted() {
    this.startReloadTimer()
  },
  beforeUnmount() {
    this.stopReloadTimer()
  },
  computed: {
    containerStyle() {
      return {
        width: `100%`,
      }
    },
    contentStyle() {
      return {
        width: `${this.drawCells.length * this.cellWidth}px`,
      }
    },
    cellStyle() {
      return {
        width: `${this.cellWidth}px`,
        height: `${this.cellHeight}px`,
      }
    },
  },
  methods: {
    init(width, height, cols) {
      this.cols = cols
      this.colsOrigin = cols
      this.cellWidth = width / this.cols;
      this.cellHeight = height / this.rows;
      this.drawCells = Array(this.cols).fill().map(() => Array(this.rows).fill(null));
    },
    onMouseOver(cell,event) {
      const top = event.clientY -190
      const left = event.clientX + 30
      this.$emit('show-popup', cell, top, left)
    },
    onMouseLeave() {
      this.$emit('hide-popup');
    },
    redraw(hists, details) {
      let curPos = {row: 0, col: 0};
      this.pCount = 0;
      this.bCount = 0;
      this.tCount = 0;
      this.ppCount = 0;
      this.bpCount = 0;
      this.drawCells = Array(this.cols).fill().map(() => Array(this.rows).fill(null));

      for (let i = 0; i < hists.length; i++) {
        let hist = hists[i]
        let detail = details ? details[i] : JSON.stringify({card: {banker: [], player: []}})
        let winTarget = -1;
        if (hists[i].startsWith('1')) {
          winTarget = 1;
          this.bCount++;
        } else if (hists[i].startsWith('2')) {
          winTarget = 2;
          this.tCount++;
        } else if (hists[i].startsWith('3')) {
          winTarget = 0;
          this.pCount++;
        }

        const isPP = hists[i].endsWith('2') || hists[i].endsWith('3');
        const isBP = hists[i][1] === '1' || hists[i][1] === '3';

        if (isPP) this.ppCount++;
        if (isBP) this.bpCount++;

        if (curPos.row >= this.rows) {
          curPos.col++;
          curPos.row = 0;
        }

        if (curPos.col >= this.drawCells.length) {
          this.drawCells.push(Array(this.rows).fill(null));
        }
        const parsedDetail = JSON.parse(detail)

        let bankerResult = 0
        let playerResult = 0

        parsedDetail.card.banker.map(e => {
          const cardNumber = parseInt(e.slice(1));  // 첫 문자를 제외한 나머지 숫자 추출
          if (!isNaN(cardNumber) && cardNumber < 10) {  // 숫자가 10 미만인 경우
            bankerResult += cardNumber;
          }
        });

        parsedDetail.card.player.map(e => {
          const cardNumber = parseInt(e.slice(1));  // 첫 문자를 제외한 나머지 숫자 추출
          if (!isNaN(cardNumber) && cardNumber < 10) {  // 숫자가 10 미만인 경우
            playerResult += cardNumber;
          }
        });

        bankerResult = bankerResult % 10;
        playerResult = playerResult % 10;
        this.drawCells[curPos.col][curPos.row] = {
          hist,
          result: winTarget,
          ppair: isPP,
          bpair: isBP,
          banker: parsedDetail.card.banker,
          player: parsedDetail.card.player,
          bankerResult,
          playerResult,
          round:i+1
        };
        curPos.row++;
      }
      this.cellWidth = this.$refs.drawerContainer.parentElement.clientWidth / this.colsOrigin; // 셀 크기를 매번 새로 구해 칸 흐름 현상을 해결
      this.cols = this.colsOrigin < this.drawCells.length ? this.drawCells.length : this.colsOrigin; // 최종 cols에서 새로운 칸 늘어나도록 컬럼 재계산

      // 스크롤을 맨 끝으로 이동
      this.$nextTick(() => {
        setTimeout(() => {
          const container = this.$refs.drawerContainer;
          if (container) {
            container.scrollLeft = container.scrollWidth;
            container.scrollTop = container.scrollHeight - container.clientHeight;
          }
        }, 1000); // 100ms 지연
      });
    },
    getImagePath(hist) {
      return `/imgs/history/${hist}.png`;
    },
    getAltText(result) {
      return result === 0 ? TEXT_PLAYER : result === 1 ? TEXT_BANKER : TEXT_TIE;
    },

    // [이미지 로딩 실패 시 리로드]
    startReloadTimer() {
      this.reloadTimer = setInterval(() => {
        this.reloadBrokenImages()
      }, this.reloadInterval)
    },
    stopReloadTimer() {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer)
      }
    },
    reloadBrokenImages() {
      if (!this.$refs.drawerContainer) return

      const images = this.$refs.drawerContainer.getElementsByTagName('img')
      for (let img of images) {
        if (!img.complete) {
          // 이미지가 로드되지 않았거나 너비가 0인 경우 (로딩 실패)
          const currentSrc = img.src
          img.src = ''
          img.src = currentSrc
          console.log('Reloading image:', currentSrc)
        }
      }
    }
  }
}
</script>

<style scoped>
.history-six-drawer {
  overflow-x: auto;
  height: 292px; /* 290 => 292 */
  //margin-top: 1px;
}

.drawer-content {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.cell {
  position: relative;
  border: 1px solid #eee;
}

.cell img {
  width: 100%;
  height: 100%;
}
</style>