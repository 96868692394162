<template>
  <canvas :width="w" :height="h" :id="'cvs-' + room._id"></canvas>

</template>


<script>
import HistorySixDrawer from "@/libraries/HistorySixDrawer"
import HistoryOrgDrawer from "@/libraries/HistoryOrgDrawer"
import History123Drawer from "@/libraries/History123Drawer"

const RESULT_PLAYER = 0;
const RESULT_BANKER = 1;
const RESULT_TIE = 2;

export default {
  name: 'room-history-panel-component',
  props: {
    currentRoom: Object
  },
  data: function () {
    return {
      cvs: null,
      drawerSix: null,
      drawerOrg: null,
      drawerOne: null,
      drawerTwo: null,
      drawerThree: null,
      tmPreview: null,
      previewCount: 0,
      currentHistoryCardIndex: -1,
      h: this.$API.util.isMobile() ? '120' : '180',
      w: this.$API.util.isMobile() ? '400' : '1200'
    }
  },
  mounted: function () {
    this.cvs = document.getElementById('cvs-' + this.currentRoom._id)

    this.drawerSix = new HistorySixDrawer(this.$API);
    this.drawerOrg = new HistoryOrgDrawer(this.$API);
    this.drawerOne = new History123Drawer(this.$API);
    this.drawerTwo = new History123Drawer(this.$API);
    this.drawerThree = new History123Drawer(this.$API);
    this.updateHistory();
  },
  computed: {
    history: function () {
      return this.currentRoom == null ? [] : this.currentRoom.histories
    },
    room: function () {
      return this.currentRoom;
    }
  },
  methods: {
    redraw: function (history) {
      // let cellWidth = this.cvs.width / 36; // 전체 셀길이 - 육매 기준
      const a = (this.$API.util.isMobile()) ? 20 : 36
      let cellWidth = this.cvs.width / a; // 전체 셀길이 - 육매 기준

      const b = (this.$API.util.isMobile()) ? 8 : 12
      let orgStartPos = { x: cellWidth * b, y: 0 };
      let oneStartPos = { x: cellWidth * b, y: this.cvs.height / 2 };

      let ctx = this.cvs.getContext("2d");

      this.drawerSix.init(ctx, 0, 0, cellWidth * 12, this.cvs.height, 12, 6);
      this.drawerSix.redraw(history, false, '');

      // let orgSize = { w: cellWidth * 35, h: }

      this.drawerOrg.init(ctx, orgStartPos.x, orgStartPos.y, cellWidth * 35, this.cvs.height / 2, 75, 6);
      this.drawerOrg.redraw(history, false, '');

      this.drawerOne.init(ctx, oneStartPos.x, oneStartPos.y, cellWidth * 35, this.cvs.height / 4, 150, 6, 1);
      this.drawerOne.redraw(this.drawerOrg.origins, false, '');

      this.drawerTwo.init(ctx, oneStartPos.x, this.cvs.height * 0.75, cellWidth * 18, this.cvs.height / 4, 77, 6, 2);
      this.drawerTwo.redraw(this.drawerOrg.origins, false, '');

      this.drawerThree.init(ctx, oneStartPos.x + cellWidth * 8, this.cvs.height * 0.75, cellWidth * 17, this.cvs.height / 4, 73, 6, 3);
      this.drawerThree.redraw(this.drawerOrg.origins, false, '');

      ctx.lineWidth = 1;
      ctx.strokeStyle = "#999";
      ctx.beginPath();
      ctx.moveTo(cellWidth * b, 0);
      ctx.lineTo(cellWidth * b, this.cvs.height);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(cellWidth * b, this.cvs.height / 2);
      ctx.lineTo(this.cvs.width, this.cvs.height / 2);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(cellWidth * b, this.cvs.height * 0.75);
      ctx.lineTo(this.cvs.width, this.cvs.height * 0.75);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(cellWidth * 20, this.cvs.height * 0.75);
      ctx.lineTo(cellWidth * 20, this.cvs.height);
      ctx.stroke();
    },
    updatePreviewResults: function (pResults, bResults) {

      let cls = [".future-one", ".future-two", ".future-three"];
      let clsPlayer = "future-blue";
      let clsBanker = "future-red";

      for (let idx = 0; idx < 3; idx++) {
        let isslash = idx == 1 ? "-circle" : (idx == 2 ? "-slash" : "");

        // future-banker 처리
        let futureBankerElements = document.querySelectorAll(".future-banker " + cls[idx]);
        futureBankerElements.forEach(element => {
          element.className = "future future-none " + cls[idx].substr(1);
          if (bResults[idx] >= 0) {
            element.classList.remove("future-none");
            element.classList.add(bResults[idx] == 1 ? clsBanker + isslash : clsPlayer + isslash);
          }

          // console.log('***banker', element.className)
        });

        // future-player 처리
        let futurePlayerElements = document.querySelectorAll(".future-player " + cls[idx]);
        futurePlayerElements.forEach(element => {
          element.className = "future future-none " + cls[idx].substr(1);
          if (pResults[idx] >= 0) {
            element.classList.remove("future-none");
            element.classList.add(pResults[idx] == 1 ? clsBanker + isslash : clsPlayer + isslash);
          }

          // console.log('***player', element.className)
        });
      }
    },
    updateHistory: function () {
      if (this.room == null) return;
      this.redraw(this.history);
      this.updatePreviewResults(this.getPreview123(RESULT_PLAYER), this.getPreview123(RESULT_BANKER));
    },
    getPreview123: function (iWinType) {
      let arrRet = [-1, -1, -1];
      for (let i = 1; i <= 3; i++) {
        if (this.drawerOrg.origins == null || this.drawerOrg.origins.length < 1) continue;
        let iCol = this.drawerOrg.origins.length - 1;
        let iRow = this.drawerOrg.origins[iCol].length - 1;
        if (iCol < i) continue;
        let lastResult = this.drawerOrg.origins[iCol][iRow];
        let baseLink = false;
        if (this.drawerOrg.origins[iCol - i] != void 0 && this.drawerOrg.origins[iCol - i][0].result == RESULT_TIE) continue;
        if (this.drawerOrg.origins[iCol - i] !== void 0 && this.drawerOrg.origins[iCol - i][iRow] !== void 0) {
          if (this.drawerOrg.origins[iCol - i][iRow + 1] !== void 0) baseLink = true;
        } else {
          baseLink = true;
        }
        let link = lastResult.result == iWinType;
        arrRet[i - 1] = baseLink == link ? RESULT_BANKER : RESULT_PLAYER;
      }
      return arrRet;
    },
    stopHistoryPreview: function () {
      if (this.tmPreview != null) {
        clearInterval(this.tmPreview);
        this.tmPreview = null;
        this.redraw(this.history);
      }
    },
    onHistoryUpdate: function (gameCode, roomId) {
      if (this.room == null || this.room.gamecode != gameCode || this.room.id != roomId) return;
      this.stopHistoryPreview();
      this.updateHistory();
    },
    renderPreviewHistory: function (vResult) {
      let t = this;
      this.stopHistoryPreview();
      this.previewCount = 0;
      if (vResult < 0) return;

      t.redraw(t.history.concat(vResult));
      this.tmPreview = setInterval(function () {
        if (t.previewCount % 2 != 0) {
          t.redraw(t.history.concat(vResult));
        } else {
          t.redraw(t.history);
        }
        t.previewCount++;
        if (t.previewCount >= 5) t.stopHistoryPreview();
      }, 500);
    },
    clearHistory: function () {
      this.stopHistoryPreview();
      this.updateHistory();
    },
    getHistoryCount () {
      return {
        b: this.drawerSix.bCount,
        p: this.drawerSix.pCount,
        t: this.drawerSix.tCount,
        pp: this.drawerSix.ppCount,
        bp: this.drawerSix.bpCount
      }
    }
  },
  created: function () {

  },
  unmounted: function () {

  },
}
</script>