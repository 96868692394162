<template>
  <room-ps-component />
</template>

<script>
import roomPsComponent from '@/components/room/ps-component.vue'

export default {
  name: "game-page",
  components: {
    roomPsComponent
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>