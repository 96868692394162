<template>
  <!-- <button type="button" @click="testShoeChange">test</button> //-->
  <template
            v-for="(room, tableIndex) in tables"
            v-bind:key="'room-' + tableIndex">
    <lobby-room-component
        :ref="'lobby_game_table_' + room._id"
        :company="company"
        :lobbyColType="lobbyColType"
        :lobbyHistoryType="lobbyHistoryType"
        :lobbyRoom="room" />
  </template>
</template>

<script>
import LobbyRoomComponent from "@/components/lobby/lobby-room-component-move.vue"
export default {
  name: 'lobby-move-component',
  props: {
    companyKey: String,
    lobbyColType: Number,
    lobbyHistoryType: Number,
  },
  emits: ['setCompany', 'setMinMax'],
  components: {
    LobbyRoomComponent
  },
  data () {
    return {
      company: null,
      tables: [],
      active: true,
      setMinMax: false
    }
  },
  methods: {
    async getTables () {
      const self = this
      // const loader = this.$loading.show({ container: this.$refs.game_lobby })
      try {
        const result = await this.$API.game.tables(this.companyKey)
        if (result.code !== 'OK') {
          // this.$refs['modal_error'].open({ title: result.code, content: result.message })
          alert(result.message)
          return
        }

        this.tables = result.tables
        for(const key in this.tables) {
          const t = this.tables[key]
          t.game = JSON.parse(t.game)
          if (this.setMinMax === false) {
            this.setMinMax = true
            this.$emit('setMinMax', t.minmax[0].pb[0], t.minmax[0].pb[1])
          }
        }

        this.company = result.company

        // 상위로 회사 정보 전달
        this.$emit('setCompany', this.company)

        // 상태 가져오기 시작한다.
        setTimeout(() => { self.getStatus().then() }, 5000)
      } catch(e) {
        this.$refs['modal_error'].open({ title: 'EXCEPTION', content: e.message })
      } //finally {
        //loader.hide()
      //}
    },
    findTable (tableKey) {
      let result = false
      for(const key in this.tables) {
        const table = this.tables[key]
        if (tableKey === table.key) {
          result = table
          break
        }
      }

      return result
    },
    async testShoeChange () {
      this.$refs['lobby_game_table_66938e1ae45d683da8683011'][0].setState('shoechange')
    },
    async getStatus () {
      const self = this
      if (!this.active) return
      try {
        const tables = []
        for(const key in this.tables) {
          const t = this.tables[key]
          if (!t.game) continue
          if (t.active !== true) continue
          tables.push({ id: t._id, key: t.key, beginPosition: t.game.historyLength})
        }

        const result = await this.$API.game.status(this.companyKey, tables)
        if (result.code !== 'OK') {
          // this.$refs['modal_error'].open({ title: result.code, content: result.message })
          alert(result.message)
          return
        }

        for(const key in result.tables) {
          const t = result.tables[key]
          if (t.game) t.game = JSON.parse(t.game)

          for(const key1 in this.tables) {
            const t2 = this.tables[key1]
            if (t2._id === t.id) {
              let historyAdded = false
              for(const idx in t.history) {
                const h = t.history[idx]
                t2.histories.push(h)
                t2.game.historyLength = t2.histories.length
                historyAdded = true
              }

              this.$refs['lobby_game_table_' + t2._id][0].setState(t.game.state)

              if (historyAdded === true) {
                this.$refs['lobby_game_table_' + t2._id][0].updateHistory()
              }

              // 상태 업데이트
              t2.game.state = t.game.state
              t2.state = t.game.state

              // 진행 시간 업데이트
              t2.game.second = t.game.second
              if (t2.game.second >= 0) {
                this.$refs['lobby_game_table_' + t2._id][0].setSecond()
              }

              break
            }
          }
        }

      } catch(e) {
        this.$log.error(e)
      } finally {
        if (this.active === true) {
          setTimeout(() => {
            self.getStatus().then()
          }, 5000)
        }
      }
    },
    updateHistory() {
      for(const key in this.tables) {
        const t = this.tables[key]
        if (t.state !== 'maintenance') {
          this.$refs['lobby_game_table_' + t._id][0].updateHistory()
        }
      }
    },
    open () {
      this.active = true
      this.getTables().then()
    },
    close () {
      this.active = false
    }
  }
}
</script>