<template>
  <div>
    <div class="game_result" id="game_result" v-if="room && !$API.util.isMobile()" ref="drawerContainer"><!-- 게임결과 -->


      <h3 class="player" :class="{'win': result.result.p === true }">플레이어 승리</h3>
      <h3 class="banker" :class="{'win': result.result.b === true }">뱅커 승리</h3>
      <h3 class="tie" :class="{'win': result.result.t === true }">타이</h3>
      <div class="result_no">
        <span id="player_result_num">{{ result.playerNumber }}</span>
        vs
        <span id="banker_result_num">{{ result.bankerNumber }}</span>
      </div>
      <ul class="player_result">
        <h2>Player</h2>
        <li id="card1">
          <div v-if="room && cards.p1 !== ''">
            <img :src="cards.p1" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card2">
          <div v-if="room && cards.p2 !== ''">
            <img :src="cards.p2" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card3" :class="{'display-none': !cards.p3}">
          <div v-if="room && cards.p3 !== ''">
            <img :src="cards.p3" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
      </ul>

      <ul class="banker_result">
        <h2>Banker</h2>
        <li id="card4">
          <div v-if="cards.b1 !== ''">
            <img :src="cards.b1" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card5">
          <div v-if="cards.b2 !== ''">
            <img :src="cards.b2" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card6" :class="{'display-none': !cards.b3}">
          <div v-if="cards.b3 !== ''">
            <img :src="cards.b3" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
      </ul>


    </div><!-- 게임결과 -->

    <div class="game_result" id="game_result_mobile" style="z-index: 99999999;"
         v-if="room && $API.util.isMobile()"><!-- 게임결과 -->


      <h3 class="player" :class="{'win': result.result.p === true }">플레이어 승리</h3>
      <h3 class="banker" :class="{'win': result.result.b === true }">뱅커 승리</h3>
      <h3 class="tie" :class="{'win': result.result.t === true }">타이</h3>
      <div class="result_no">
        <span id="player_result_num">{{ result.playerNumber }}</span>
        vs
        <span id="banker_result_num">{{ result.bankerNumber }}</span>
      </div>
      <ul class="player_result">
        <h2>Player</h2>
        <li id="card1">
          <div v-if="room && cards.p1 !== ''">
            <img :src="cards.p1" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card2">
          <div v-if="room && cards.p2 !== ''">
            <img :src="cards.p2" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card3" :class="{'display-none': !cards.p3}">
          <div v-if="room && cards.p3 !== ''">
            <img :src="cards.p3" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
      </ul>

      <ul class="banker_result">
        <h2>Banker</h2>
        <li id="card4">
          <div v-if="cards.b1 !== ''">
            <img :src="cards.b1" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card5">
          <div v-if="cards.b2 !== ''">
            <img :src="cards.b2" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
        <li id="card6" :class="{'display-none': !cards.b3}">
          <div v-if="cards.b3 !== ''">
            <img :src="cards.b3" @load="imageLoaded" @error="imageLoaded">
          </div>
        </li>
      </ul>


    </div><!-- 게임결과 -->
  </div>
</template>

<script>

export default {
  name: 'room-card-panel-component',
  props: {
    room: Object,
    isMultiRoom: Boolean,
    reloadInterval: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      cards: {
        p1: "", p2: "", p3: "", b1: "", b2: "", b3: ""
      },
      imageWin: '/resources/img/win/pwin.png',
      result: {
        result: {
          p: false,
          b: false,
          t: false,
          pp: false,
          bp: false
        },
        playerNumber: 0,
        bankerNumber: 0,
      },
      imagesLoaded: 0, // 로드된 이미지 수
      totalImages: 6,  // 로드할 총 이미지 수

      reloadTimer: null
    }
  },
  computed: {
    playerLabel: function () {
      return "Player";
    },
    bankerLabel: function () {
      return "Banker";
    },
    playerShortLabel: function () {
      return "P";
    },
    bankerShortLabel: function () {
      return "B";
    },
  },
  methods: {
    getCardImg: function (c) {
      if (!c) return ''

      if (this.$API.util.isMobile()) {
        return "/ps/images/card-m/" + c + ".png";
      } else {
        return "/ps/images/card/" + c + ".png";
      }
    },
    imageLoaded: function () {
      this.imagesLoaded++;
      if (this.imagesLoaded === this.totalImages) {
        this.$emit('images-loaded');  // 이벤트 발생
      }
    },
    setRoundResult: function (result) {
      this.result = result;

      // 초기화
      this.imagesLoaded = 0;
      this.totalImages = 0;

      // 플레이어 카드 설정
      if (result.cards.player[0]) {
        this.cards.p1 = this.getCardImg(result.cards.player[0]);
        this.totalImages++;
      } else {
        this.cards.p1 = '';
      }

      if (result.cards.player[1]) {
        this.cards.p2 = this.getCardImg(result.cards.player[1]);
        this.totalImages++;
      } else {
        this.cards.p2 = '';
      }

      if (result.cards.player[2]) {
        this.cards.p3 = this.getCardImg(result.cards.player[2]);
        this.totalImages++;
      } else {
        this.cards.p3 = '';
      }

      // 뱅커 카드 설정
      if (result.cards.banker[0]) {
        this.cards.b1 = this.getCardImg(result.cards.banker[0]);
        this.totalImages++;
      } else {
        this.cards.b1 = '';
      }

      if (result.cards.banker[1]) {
        this.cards.b2 = this.getCardImg(result.cards.banker[1]);
        this.totalImages++;
      } else {
        this.cards.b2 = '';
      }

      if (result.cards.banker[2]) {
        this.cards.b3 = this.getCardImg(result.cards.banker[2]);
        this.totalImages++;
      } else {
        this.cards.b3 = '';
      }

      let resultImage = '';
      if (result.result.p === true) resultImage = 'pwin';
      if (result.result.t === true) resultImage = 'twin';
      if (result.result.b === true) resultImage = 'bwin';

      resultImage += result.result.pp && result.result.bp ? '-f' : result.result.pp ? '-p' : result.result.bp ? '-b' : '';

      this.imageWin = "/resources/img/win/" + resultImage + ".png";
    },

    // [이미지 로딩 실패 시 리로드]
    startReloadTimer() {
      this.reloadTimer = setInterval(() => {
        this.reloadBrokenImages()
      }, this.reloadInterval)
    },
    stopReloadTimer() {
      if (this.reloadTimer) {
        clearInterval(this.reloadTimer)
      }
    },
    reloadBrokenImages() {
      if (!this.$refs.drawerContainer) return

      const images = this.$refs.drawerContainer.getElementsByTagName('img')
      for (let img of images) {
        if (!img.complete) {
          // 이미지가 로드되지 않았거나 너비가 0인 경우 (로딩 실패)
          const currentSrc = img.src
          img.src = ''
          img.src = currentSrc
          console.log('Reloading image:', currentSrc)
        }
      }
    }
  },
  mounted() {
    this.startReloadTimer()
  },
  beforeUnmount() {
    this.stopReloadTimer()
  },
}
</script>