<template>
  <div class="room-history-panel" :class="{'mobile': isMobile}">
    <table class="history-table">
      <tr>
        <td class="history-left">
          <HistorySixDrawer ref="drawerSix" @show-popup="showPopup" @hide-popup="hidePopup"/>
        </td>
        <td class="history-right">
          <table class="history-right-table">
            <tr>
              <td colspan="2" class="history-org">
                <HistoryOrgDrawer ref="drawerOrg"/>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="history-123-full">
                <History123Drawer ref="drawerOne" :draw-type="1"/>
              </td>
            </tr>
            <tr class="history-123-bottom">
              <td class="history-123-half">
                <History123Drawer ref="drawerTwo" :draw-type="2"/>
              </td>
              <td class="history-123-half">
                <History123Drawer ref="drawerThree" :draw-type="3"/>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import HistorySixDrawer from '@/components/history/HistorySixDrawer.vue'
import HistoryOrgDrawer from '@/components/history/HistoryOrgDrawer.vue'
import History123Drawer from '@/components/history/History123Drawer.vue'

const RESULT_PLAYER = 0;
const RESULT_BANKER = 1;
const RESULT_TIE = 2;

export default {
  name: 'room-history-panel-component',
  components: {
    HistorySixDrawer,
    HistoryOrgDrawer,
    History123Drawer
  },
  props: {
    currentRoom: Object,
    drawCells: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tmPreview: null,
      previewCount: 0,
      currentHistoryCardIndex: -1,
      isMobile: false,
      isPopupVisible: false,
      popupPosition: {
        top: 0,
        left: 0,
      },
      activeCell: null,
    }
  },
  computed: {
    history() {
      return this.currentRoom == null ? [] : this.currentRoom.histories
    },
    detail(){
      return this.currentRoom == null ? [] : this.currentRoom.detail
    },
    room() {
      return this.currentRoom;
    }
  },
  methods: {
    showPopup(cell, top, left) {
      this.$emit('show-popup', { cell, top, left });  // 상위 부모로 전달
    },
    hidePopup() {
      this.$emit('hide-popup');  // 상위 부모로 전달
    },
    filledCards(name) {
      const cards = this.activeCell?.[name] || [];
      const filledCards = [...cards];
      while (filledCards.length < 3) {
        filledCards.push(null);
      }
      return filledCards;
    },
    updateHistory() {
      if (this.room == null) return;
      this.$refs.drawerSix.redraw(this.history,this.detail);
      this.$refs.drawerOrg.redraw(this.history);
      const orgOrigins = this.$refs.drawerOrg.getOrigins();
      this.$refs.drawerOne.redraw(orgOrigins);
      this.$refs.drawerTwo.redraw(orgOrigins);
      this.$refs.drawerThree.redraw(orgOrigins);
      this.updatePreviewResults(this.getPreview123(RESULT_PLAYER), this.getPreview123(RESULT_BANKER));
    },
    getPreview123(iWinType) {
      let arrRet = [-1, -1, -1];
      for (let i = 1; i <= 3; i++) {
        if (this.$refs.drawerOrg.origins == null || this.$refs.drawerOrg.origins.length < 1) continue;
        let iCol = this.$refs.drawerOrg.origins.length - 1;
        let iRow = this.$refs.drawerOrg.origins[iCol].length - 1;
        if (iCol < i) continue;
        let lastResult = this.$refs.drawerOrg.origins[iCol][iRow];
        let baseLink = false;
        if (this.$refs.drawerOrg.origins[iCol - i] != void 0 && this.$refs.drawerOrg.origins[iCol - i][0].result == RESULT_TIE) continue;
        if (this.$refs.drawerOrg.origins[iCol - i] !== void 0 && this.$refs.drawerOrg.origins[iCol - i][iRow] !== void 0) {
          if (this.$refs.drawerOrg.origins[iCol - i][iRow + 1] !== void 0) baseLink = true;
        } else {
          baseLink = true;
        }
        let link = lastResult.result == iWinType;
        arrRet[i - 1] = baseLink == link ? RESULT_BANKER : RESULT_PLAYER;
      }
      return arrRet;
    },
    updatePreviewResults(pResults, bResults) {
      let cls = [".future-one", ".future-two", ".future-three"];
      let clsPlayer = "future-blue";
      let clsBanker = "future-red";

      for (let idx = 0; idx < 3; idx++) {
        let isslash = idx == 1 ? "-circle" : (idx == 2 ? "-slash" : "");

        // future-banker 처리
        let futureBankerElements = document.querySelectorAll(".future-banker " + cls[idx]);
        futureBankerElements.forEach(element => {
          element.className = "future future-none " + cls[idx].substr(1);
          if (bResults[idx] >= 0) {
            element.classList.remove("future-none");
            element.classList.add(bResults[idx] == 1 ? clsBanker + isslash : clsPlayer + isslash);
          }

          // console.log('***banker', element.className)
        });

        // future-player 처리
        let futurePlayerElements = document.querySelectorAll(".future-player " + cls[idx]);
        futurePlayerElements.forEach(element => {
          element.className = "future future-none " + cls[idx].substr(1);
          if (pResults[idx] >= 0) {
            element.classList.remove("future-none");
            element.classList.add(pResults[idx] == 1 ? clsBanker + isslash : clsPlayer + isslash);
          }

          // console.log('***player', element.className)
        });
      }
    },
    stopHistoryPreview() {
      if (this.tmPreview != null) {
        clearInterval(this.tmPreview);
        this.tmPreview = null;
        this.updateHistory();
      }
    },
    onHistoryUpdate(gameCode, roomId) {
      if (this.room == null || this.room.gamecode != gameCode || this.room.id != roomId) return;
      this.stopHistoryPreview();
      this.updateHistory();
    },
    redraw: function(history) {
      let t = this;
      this.initDrawers()
      t.$refs.drawerSix.redraw(history);
      t.$refs.drawerOrg.redraw(history);
      const orgOrigins = t.$refs.drawerOrg.getOrigins();
      t.$refs.drawerOne.redraw(orgOrigins);
      t.$refs.drawerTwo.redraw(orgOrigins);
      t.$refs.drawerThree.redraw(orgOrigins);
    },
    renderPreviewHistory: function (vResult) {
      let t = this;
      this.stopHistoryPreview();
      this.previewCount = 0;
      if (vResult < 0) return;

      t.redraw(t.history.concat(vResult));
      this.tmPreview = setInterval(function () {
        if (t.previewCount % 2 != 0) {
          t.redraw(t.history.concat(vResult));
        } else {
          t.redraw(t.history);
        }
        t.previewCount++;
        if (t.previewCount >= 5) t.stopHistoryPreview();
      }, 500);
    },
    clearHistory() {
      this.stopHistoryPreview();
      this.updateHistory();
    },
    getHistoryCount() {
      return {
        b: this.$refs.drawerSix.bCount,
        p: this.$refs.drawerSix.pCount,
        t: this.$refs.drawerSix.tCount,
        pp: this.$refs.drawerSix.ppCount,
        bp: this.$refs.drawerSix.bpCount
      }
    },
    initDrawers() {
      this.isMobile = this.$API.util.isMobile();
      const width = this.$el.clientWidth;

      if (this.isMobile) {
        const cellWidth = width / 21; // 전체 가로를 21로 나눈 셀 크기
        const height = cellWidth * 6; // 높이는 셀 크기의 6배

        this.$refs.drawerSix.init(cellWidth * 8, height, 8);
        this.$refs.drawerOrg.init(cellWidth * 13, height * 0.5, 26);
        this.$refs.drawerOne.init(cellWidth * 13, height * 0.25, 52);
        this.$refs.drawerTwo.init(cellWidth * 7, height * 0.25, 28);
        this.$refs.drawerThree.init(cellWidth * 6, height * 0.25, 24);
      } else {
        // 데스크톱 레이아웃 (변경 없음)
        this.$refs.drawerSix.init(571, 286, 12);
        this.$refs.drawerOrg.init(1008 + 120, 144, 42);
        this.$refs.drawerOne.init(1008 + 120, 72, 84);
        this.$refs.drawerTwo.init(504 + 60, 72, 42);
        this.$refs.drawerThree.init(504 + 60, 72, 42);
      }

      this.updateHistory();
    },
  },
  mounted() {

    /*
    let width = document.documentElement.clientWidth
    console.log('***', width)

    const a = (this.$API.util.isMobile()) ? 20 : 36
    let cellWidth = width / a; // 전체 셀길이 - 육매 기준

    if (this.$API.util.isMobile()) {
      const obj = document.querySelector('.room-history-panel')
      obj.style.height = '157px'

      this.$refs.drawerSix.init(cellWidth * 6, 140, 6);
      this.$refs.drawerOrg.init(cellWidth * 35, 70, 42);
      this.$refs.drawerOne.init(cellWidth * 35, 40, 84);
      this.$refs.drawerTwo.init(cellWidth * 18, 40, 42);
      this.$refs.drawerThree.init(cellWidth * 17, 40, 42);
    } else {
      this.$refs.drawerSix.init(571, 286, 12);
      this.$refs.drawerOrg.init(1008, 144, 42);
      this.$refs.drawerOne.init(1008, 72, 84);
      this.$refs.drawerTwo.init(504, 72, 42);
      this.$refs.drawerThree.init(504, 72, 42);
    }
    */

    // this.updateHistory();
    this.$nextTick(() => {
      this.initDrawers();
      window.addEventListener('resize', this.initDrawers);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.initDrawers);
  }
}
</script>

<style scoped>
.room-history-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.history-container {
  display: flex;
}

.history-left {
  width: 571px; /* 데스크톱 기준 */
  border-right: 2px solid #aaa;
}

.history-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 1128px; /* 데스크톱 기준  1008 + 120  출목표 수정*/
}

.history-org {
  height: 144px; /* 데스크톱 기준 */
  border-bottom: 2px solid #aaa;
}

.history-123-container {
  display: flex;
  flex-direction: column;
}

.history-123-full {
  width: 100%;
  height: 72px; /* 데스크톱 기준 */
}

.history-123-bottom {
  display: flex;
  border-top: 2px solid #aaa;
}

.history-123-half {
  width: 564px; /* 데스크톱 기준  504 + 60 출목표*/
  height: 72px; /* 데스크톱 기준 */
}

.history-123-half:first-child {
  border-right: 2px solid #aaa;
}

/* 모바일 스타일 */
.mobile .history-container {
  flex-direction: column;
}

.mobile .history-left,
.mobile .history-right {
  width: 100%;
}

.mobile .history-org,
.mobile .history-123-full,
.mobile .history-123-half {
  height: auto;
}

.mobile .history-123-bottom {
  flex-direction: row;
}

.mobile .history-123-half {
  width: 50%;
}

.mobile .history-left,
.mobile .history-org,
.mobile .history-123-full,
.mobile .history-123-half {

}

.mobile .history-org,
.mobile .history-123-full,
.mobile .history-123-bottom {
  border-top: 2px solid #aaa;
}

.mobile .history-123-half:first-child {
  border-right: 2px solid #aaa;
}
</style>